import { Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { NgxCopilotService } from 'ngx-copilot';
import { BehaviorSubject, combineLatest, Observable, scheduled, Subject } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { ListItemComponent } from 'src/app/common/list-item/list-item.component';
import { ActivityService } from 'src/app/providers/activity-service';
import { Appapi } from 'src/app/providers/appapi';
import * as appStore from '../../store';
import { CoursesService } from 'src/app/providers/courses-service';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { UiuxService } from 'src/app/services/uiux.service';
import { WalkthroughService } from 'src/app/services/walkthrough.service';
import { BookingStatusVO, CourseVO, OrganisationVO, ServerAPIResponseVO, UserActivityVO } from 'src/app/valueObjects/core.vo';
import { ActivityListItemVO } from 'src/app/valueObjects/lists.vo';
import { MyUtil } from '../../../libs/MyUtil';
import { Store } from '@ngrx/store';
import { getActivityListFilterState, getBrandName } from 'src/app/store/selectors/view.selector';
import { FindActivitiesFilters } from 'src/app/store/reducers/view.reducer';
import { Title } from '@angular/platform-browser';
import * as moment from 'moment';

@Component({
  selector: 'page-activity-list',
  templateUrl: 'activity-list.html',
  styleUrls: ['activity-list.scss']
})

export class ActivityListPage implements OnInit, OnDestroy {

  constructor(public router: Router,
    private route: ActivatedRoute,
    private copilot: NgxCopilotService,
    public navParams: NavParams,
    public formBuilder: UntypedFormBuilder,
    public zone: NgZone,
    public uiux: UiuxService,
    public appapi: Appapi,
    public desktopChecker: DesktopChecker,
    public activityService: ActivityService,
    public coursesService: CoursesService,
    private store: Store<appStore.AppViewState>,
    public courseService: CoursesService,
    private titleService:Title) {
      this.titleService.setTitle("Find Activities");
    }

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  menuOpened() {
    setTimeout(() => {
      document.getElementById('organisations').focus();
    }, 0);
  }

  // Component variables.
  component = ListItemComponent;
  @ViewChild('Content', { static: true }) content;
  routeData: any = {};
  pageLabel = "ActivityListPage";
  displayHelpIcon = true;
  isMobileView = this.uiux.isMobileView();
  brandName$: Observable<string> = this.store.select(getBrandName);
  activityFilterState$: Observable<FindActivitiesFilters> = this.store.select(getActivityListFilterState);
  activitiesList$: BehaviorSubject<UserActivityVO[]> = new BehaviorSubject([]);
  myActivitiesList$: BehaviorSubject<UserActivityVO[]> = new BehaviorSubject([]);
  tabSelection$: BehaviorSubject<string> = new BehaviorSubject('scheduled');
  loading = true;
  courseList$: BehaviorSubject<CourseVO[]> = new BehaviorSubject([]);
  bookingStatusList$: BehaviorSubject<BookingStatusVO[]> = new BehaviorSubject([]);
  courseBookingStatusList$: BehaviorSubject<BookingStatusVO[]> = new BehaviorSubject([]);
  filterForm = this.formBuilder.group({
    searchTerm: '',
    organisations: [],
    attendanceType: [],
    skills: [],
    start: '',
    end: '',
  });
  organisations = [];
  attendanceTypes = MyUtil.getAttendanceTypeChain();
  programIds = MyUtil.getProfileProgramIds();
  skills = [];
  isDirtyForm = false;
  findActivitiesFilters: FindActivitiesFilters;

  /** 
  * All ativites and courses as ActivityListItem[].
  */
  activityListItems$: Observable<ActivityListItemVO[]> = combineLatest([
    this.activitiesList$,
    this.courseList$,
    this.tabSelection$,
    this.filterForm.valueChanges.pipe(debounceTime(200)),
    this.bookingStatusList$,
    this.courseBookingStatusList$,
    this.myActivitiesList$,
    this.brandName$
  ]).pipe(takeUntil(this.destroy$), map(([activities, courses, tabSelection, formValues, bookingStatus, courseBookingStatus, myActivities, brandName]) => {

  const start = new Date(formValues.start).getTime() / 1000;
  const stillUtc = moment.utc(formValues.end).toDate();
  const local = moment(stillUtc).local().format('YYYY-MM-DD');
  const end = new Date( local).getTime()/1000;


  const nowTimestamp = new Date();
  //console.log('formValues.start', formValues.start)
  //console.log('nowTimestamp', nowTimestamp)

  const stillUtcNow = moment.utc(nowTimestamp).toDate();
  const localNow = moment(stillUtcNow).local().format('YYYY-MM-DD');
  const now = new Date(localNow).getTime()/1000;
  //console.log('now', now)

    if (start > end) {
      MyUtil.presentToast('Date range invalid. End date must be greater than start date.', { cssClass: 'inkpath-toast' });
      return;
    }
    // Limit to 50 days
    let dateLimit = new Date();
    let days = 50;
    dateLimit.setDate(dateLimit.getDate() - days) ;
    let dateLimitTimestamp = dateLimit.getTime();
    if (Math.round(dateLimitTimestamp/ 1000) > start) {
      MyUtil.presentToast('Historical activities are limited to 50 days unless they already exist in your activities list and goals.', { cssClass: 'inkpath-toast' });
    }

    let formValuesToStore = {
      searchTerm: formValues.searchTerm,
      organisations: formValues.organisations,
      attendanceType: formValues.attendanceType,
      skills: formValues.skills,
      start: formValues.start,
      end: formValues.end,
    }

    // Booking status list.
    const homeOrg: OrganisationVO = MyUtil.getRootOrganization();

    let myActivityIds = [];
    for (const myActivity of myActivities) {
      myActivityIds[myActivity.activity_id] = myActivity.activity_id;
    };

    // Set activities as ActivityListItem VO's.
    let activityList: ActivityListItemVO[] = []
    activities.forEach((activity: any) => {

      if (activity) {
        //Work out if this activity clashes with anything the user has already booked
        let activityBookingClash = MyUtil.getBookingClashStatus(activity, bookingStatus);
        let desc = (activity?.description)? activity?.description: '';
       
        const userActivityVO: ActivityListItemVO = {
          id: activity.id,
          name: activity.name,
          booking_type: activity.booking_type,
          provider: activity.provider,
          period: activity.period,
          start_at: activity.start_at,
          description: desc,
          time_logged: activity.time_logged,
          end_at: activity.end_at,
          badge_details: activity.badge_details,
          price: activity.price,
          programs: activity.programs,
          phases: activity.phases,
          status: activity.status,
          internal_reference: activity.internal_reference,
          venue: activity.venue,
          visible: activity.visible,
          activity_template_id: activity.activity_template_id,
          attendance_type: activity.attendance_type,
          homeOrg: homeOrg,
          oid: activity.oid,
          skills: activity.skills,
          bookingStatus: bookingStatus[activity.id],
          bookingClash: activityBookingClash,
          added: !!myActivityIds[activity.id],
          code: activity.code,
          is_course: 0
        }
        activityList.push(userActivityVO)
      }
    })

    // Set courses as ActivityListItem VO's.
    let coursesList: ActivityListItemVO[] = [];
    for (const key in courses) {

      //Get the course booking status
      let courseId = courses[key].id;
      let bookingFound = courseBookingStatus[courseId] !== undefined;
      courses[key].is_booked = (bookingFound ? courseBookingStatus[courseId].is_booked : 0);
      courses[key].on_waiting_list = (bookingFound ? courseBookingStatus[courseId].on_waiting_list : 0);

      let desc = (courses[key].description)? courses[key].description: '';

      //Work out if any of the course's activities clash with anything the user has already booked
      let courseBookingClash = false;
      for (const courseActivityId in courses[key].course_activities) {
        //If this course is booked, don't check for a clash if this activity isn't booked
        if ((courses[key].is_booked || courses[key].on_waiting_list) &&
          (!bookingStatus[courseActivityId] || !(bookingStatus[courseActivityId].is_booked == 1 || bookingStatus[courseActivityId].on_waiting_list == 1))) {
          continue;
        }
        courseBookingClash = MyUtil.getBookingClashStatus(courses[key].course_activities[courseActivityId], bookingStatus);
        if (courseBookingClash) {
          break;
        }
      }

      const userActivityVO: ActivityListItemVO = {
        id: courses[key].id,
        name: courses[key].name,
        booking_type: courses[key].id,
        provider: courses[key].provider,
        period: courses[key].period,
        start_at: courses[key].start_at,
        description: desc,
        end_at: courses[key].end_at,
        badge_details: courses[key].badge_details,
        price: courses[key].price,
        programs: courses[key].programs,
        phases: courses[key].phases,
        internal_reference: courses[key].internal_reference,
        code: courses[key].code,
        skills: courses[key].skills,
        visible: courses[key].visible,
        is_course: 1,
        is_hybrid: courses[key].is_hybrid == 1,
        homeOrg: homeOrg,
        oid: courses[key].oid,
        is_booked: courses[key].is_booked,
        on_waiting_list: courses[key].on_waiting_list,
        bookingClash: courseBookingClash,
        added: courses[key].is_booked,
        booking_opens_ts: courses[key].booking_opens_ts,
        booking_closes_ts: courses[key].booking_closes_ts,
        booking_opens_at: courses[key].booking_opens_at,
        booking_closes_at: courses[key].booking_closes_at,
        capacity: courses[key].capacity,
        online_capacity: courses[key].online_capacity,
        bookings: courses[key].bookings,
        onlineBookings: courses[key].online_bookings,
        terms_text: courses[key].terms_text,
      }
      coursesList.push(userActivityVO);
    }
    // Combine arrays.
    let activitiesAndCoursesList = [...activityList, ...coursesList];


    /**
    * All below lines just filter the activitiesAndCoursesList array (The main list).
    * So add additional filtering in the list below if required (copy and paste what you will)...
    */
    // Filter by: search.
    if (formValues.searchTerm && formValues.searchTerm !== null) {
      activitiesAndCoursesList = activitiesAndCoursesList.filter(activity => {
       
        return (
          activity.name.toLowerCase().indexOf(formValues.searchTerm.toLowerCase()) !== -1 ||
          activity?.description.toLowerCase().indexOf(formValues.searchTerm.toLowerCase()) !== -1 
        );
      });
    }

    //
    if (tabSelection === "self-study") {
      // Self-study view
      activitiesAndCoursesList = activitiesAndCoursesList.filter(activity => {
        if (activity.attendance_type) {
          return activity.attendance_type === 3;
        }
      });

      // Filter by: date range.
      activitiesAndCoursesList = activitiesAndCoursesList.filter(activity => {
      // activity start <= today <= activity end
        return (parseInt(activity.start_at) <= now) && (now <= parseInt(activity.end_at));
      });  
    } else {

      // Filter out Self-study view
      activitiesAndCoursesList = activitiesAndCoursesList.filter(activity => {
        if(activity.is_course === 1){
          return true;
        }
        return activity.attendance_type !== 3;  
      });
      // Scheduled view
      // Filter by: date range.
      activitiesAndCoursesList = activitiesAndCoursesList.filter(activity => {
        //SEARCH START <= ACTIVITY END <= SEARCH END 
        return   (start <= parseInt(activity.end_at) && parseInt(activity.end_at) <= end) 
              || (start <= parseInt(activity.start_at) && parseInt(activity.start_at) <= end); 
        //return (parseInt(activity.end_at) <= end && parseInt(activity.start_at) >= start);
      });  

      // Filter by: attendance types. 
      if (formValues.attendanceType) {
        activitiesAndCoursesList = activitiesAndCoursesList.filter(activity => {
          // Stop from filtering out courses, as courses do not have an attendance type.
          if (activity.is_course) { 
            return true;
          }
          return (formValues.attendanceType.indexOf(`${activity.attendance_type}`) !== -1) ? true : false;
        });
      }
    }

    // Filter by: organisation.
    if (formValues.organisations && formValues.organisations.length !== 0 && brandName !== 'rsc') {
      activitiesAndCoursesList = activitiesAndCoursesList.filter(activity => {
        return (formValues.organisations.indexOf(activity.oid) !== -1) ? true : false;
      });
    }
    // Filter by: attendance skills.
    if (formValues.skills && formValues.skills.length !== 0) {
      activitiesAndCoursesList = activitiesAndCoursesList.filter(activity => {
        if (activity.skills) {
          return formValues.skills.filter((skillId) => {
            if (activity.skills.indexOf(parseInt(skillId)) !== -1) { return true; }
          }).length !== 0;
        }
      });
    }

    // Filter by: provided by organisation.
    activitiesAndCoursesList = activitiesAndCoursesList.filter(activity => {
      return this.organisations.filter(item => {
        if (activity.provider && item.name) {
          return (activity.oid == item.id || activity?.provider.toUpperCase() == item.name.toUpperCase() || activity.affFlag);
        }
      }).length !== 0;
    });
    // Filter by: Filter out private activities.
    activitiesAndCoursesList = activitiesAndCoursesList.filter(item => { return item.visible == 1 });
    // Filter by: program.
    activitiesAndCoursesList = activitiesAndCoursesList.filter(this.isPartOfProgram(MyUtil.getProfileProgramIds()));
    // Filter by: phases.
    let prof = MyUtil.getProfile();
    let phasekeys = [prof.phase_id];
    if (!!prof.fundings) {
      let phases = Object.keys(prof.fundings);
      phases.forEach(phase => {
        phasekeys.push(prof.fundings[phase].phase_id);
      });
    }
    activitiesAndCoursesList = activitiesAndCoursesList.filter(activity => {
      let contains = false;
      if (activity.phases.length > 0) {
        activity.phases.forEach(element => {
          if (phasekeys.indexOf(element) > -1) {
            contains = true;
          }
        });
      } else {
        contains = true;
      }
      return contains;
    });
    // Filtering ends here... lets get on with something else...
    // Set form as dirty.
    this.setFormDirtyState(formValuesToStore);
    // Sort by alphabetically at - Or change to what ever you want... (Sorts all lists).
    return activitiesAndCoursesList.sort((a: any, b: any) => a.start_at - b.start_at)
  }))

  /** 
  * Set walkthrough state. 
  * @param pageName   Name of page.
  * @param value      Boolean - (Has been visited or not).
  */
  setWalkthroughStateHandler(pageName: string, value: boolean) {
    WalkthroughService.setWalkthroughState(pageName, value)
  }

  /** 
  * Re initialize and specify step.
  * @param stepNumber   stepNumber: string.
  */
  initPosition = (stepNumber: any) => this.copilot.checkInit(stepNumber);

  /** 
  * Next step.
  * @param stepNumber   stepNumber: string.
  */
  nextStep = (stepNumber: any) => this.copilot.next(stepNumber);

  /** 
  * Finish copilot walkthroughs.
  */
  done = () => this.copilot.removeWrapper();

  /** 
  * Start walkthroughs.
  */
  startWalkthrough() {
    if (!WalkthroughService.isWalkthroughComplete(this.pageLabel) && !WalkthroughService.allWalkthroughsDisabled()) {
      setTimeout(() => {
        this.copilot.checkInit('18');
      }, 1000);
    }
    this.displayHelpIcon = !WalkthroughService.allWalkthroughsDisabled();
  }

  /** 
  * Resets form.
  */
  resetForm() {
    this.store.dispatch(new appStore.FindActivitiesFilterStateSet(undefined));
    this.filterForm.reset()
    this.setDefaultFormValues();
  }

  /** 
  * Sets form dirty state.
  */
  setFormDirtyState(formValuesToStore) {
    Object.values(this.filterForm.controls).map(value => {
      if (value.dirty) {
        this.store.dispatch(new appStore.FindActivitiesFilterStateSet(formValuesToStore));
        this.isDirtyForm = true;
      }
    });
  }

  /** 
  * Sets skills list
  */
  setSkillsList() {
    let skills = MyUtil.getSkills()
    for (const key in skills) {
      if (skills.hasOwnProperty(key)) {
        this.skills.push(skills[key])
      }
    }
  }

  // On init.
  async ngOnInit() {

    this.activityFilterState$.pipe(takeUntil(this.destroy$)).subscribe((findActivitiesFilters: FindActivitiesFilters) => {
      this.findActivitiesFilters = findActivitiesFilters;
    });

    // Set activity booking status.
    this.activityService.listBookingStatus().then((bookingStatusList: BookingStatusVO[]) => {
      this.bookingStatusList$.next(bookingStatusList);
    });

    // Set activity booking status.
    this.courseService.listBookingStatus().then((courseBookingStatusList: BookingStatusVO[]) => {
      this.courseBookingStatusList$.next(courseBookingStatusList);
    });

    // Sync and get activities and courses.
    await this.appapi.checkAndRefreshProfile().then(async () => {
      await this.appapi.syncAllActivities().then(async () => {
        /// Fill course list.
        await this.courseService.getAvailableCourses().then(async (res: ServerAPIResponseVO) => {
          let courseList = [];
          let inList = [];
          if (res.data) {
            Object.keys(res.data).forEach((prop) => {
              if(!inList.includes(res.data[prop].id)){
                inList.push(res.data[prop].id)
              // Object display setup.
              res.data[prop].courseFlag = true;
              let startDisplay = MyUtil.formatUnixTimeStampDate(res.data[prop].activityDateRange.start_date);
              let endDisplay = MyUtil.formatUnixTimeStampDate(res.data[prop].activityDateRange.end_date);
              let startTime = MyUtil.formatHISTimeFormatToAMPM(res.data[prop].start_time);
              let endTime = MyUtil.formatHISTimeFormatToAMPM(res.data[prop].end_time);
              res.data[prop].period = (startDisplay === endDisplay ? startDisplay + (startTime === "00:00" ? '' : ' ' + startTime) : 'from ' + startDisplay + (startTime === "00:00" ? '' : ' ' + startTime) + ' to ' + endDisplay + (endTime === "00:00" ? '' : ' ' + endTime));
              res.data[prop].start_at = res.data[prop].activityDateRange.start_date;
              res.data[prop].end_at = res.data[prop].activityDateRange.end_date;
              res.data[prop].attendance_type = res.data[prop].attendance_type;
              courseList.push(res.data[prop]);
              }
            });
          }

          
          await this.courseService.getAvailableAffiliateCourses().then(async (res) => {
            
            if (res.data) {
              Object.keys(res.data).forEach((prop) => {
                if(!inList.includes(res.data[prop].id)){
                  inList.push(res.data[prop].id)
                  // Object display setup for affiliate courses.
                  res.data[prop].courseFlag = true;
                  res.data[prop].affFlag = true;
                  let startDisplay = MyUtil.formatUnixTimeStampDate(res.data[prop].activityDateRange.start_date);
                  let endDisplay = MyUtil.formatUnixTimeStampDate(res.data[prop].activityDateRange.end_date);
                  let startTime = MyUtil.formatHISTimeFormatToAMPM(res.data[prop].start_time);
                  let endTime = MyUtil.formatHISTimeFormatToAMPM(res.data[prop].end_time);
                  res.data[prop].period = (startDisplay === endDisplay ? startDisplay + (startTime === "00:00" ? '' : ' ' + startTime) : 'from ' + startDisplay + (startTime === "00:00" ? '' : ' ' + startTime) + ' to ' + endDisplay + (endTime === "00:00" ? '' : ' ' + endTime));
                  res.data[prop].start_at = res.data[prop].activityDateRange.start_date;
                  res.data[prop].end_at = res.data[prop].activityDateRange.end_date;
                  courseList.push(res.data[prop]);
                }
              });
            }
          });
          this.courseList$.next(courseList);
        });
        // Fill activity lists.
        let mergedActivities: any[] = Object.values({ ...MyUtil.cache[MyUtil.DOC_ID.ACTIVITIES], ...MyUtil.cache[MyUtil.DOC_ID.FUNDING_ACTIVITIES] });
        mergedActivities.map((item, k) => {
          let startDisplay = MyUtil.formatUnixTimeStampDate(item.start_at);
          let endDisplay = MyUtil.formatUnixTimeStampDate(item.end_at);

          let startTime = MyUtil.formatHISTimeFormatToAMPM(item.start_time);
          let endTime = MyUtil.formatHISTimeFormatToAMPM(item.end_time);

          item.organization = MyUtil.getOrgnizationChainName(item.oid);
          let org = MyUtil.cache[MyUtil.DOC_ID.USER_ORGANIZATIONS][item.oid];
          if (org) {
            item.level = org.level;
          } else {
            item.level = 0;
            MyUtil.error('Not found org: ' + item.oid + ' for activity: [' + item.id + '] ' + item.name);
          }
          item.start_time_date = startDisplay;
          item.end_time_date = endDisplay;
          item.period = (startDisplay === endDisplay ? startDisplay + (startTime === "00:00" ? '' : ' ' + startTime) : 'from ' + startDisplay + (startTime === "00:00" ? '' : ' ' + startTime) + ' to ' + endDisplay + (endTime === "00:00" ? '' : ' ' + endTime));
          return item;
        });
        this.activitiesList$.next(mergedActivities);

        await this.appapi.queryUserActivity().then((result: UserActivityVO[]) => {
          this.myActivitiesList$.next(result);
        });
      })

      let fundingOrgs = (MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_ORGANIZATIONS]) ? MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_ORGANIZATIONS] : {};
      this.organisations = [...MyUtil.getOrgnizationChain(), ...Object.values(
        fundingOrgs
      )].filter((a) => a.id);

    }).then(async () => {
      this.loading = false;
    });

    MyUtil.firebaseSetScreenName('activity-list');
    MyUtil.firebaseLogEvent('view_did_enter', { name: 'activity-list', data: this.navParams.data });

    // Set route data.
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData);
      // Set the skills array ready for populating the lists.
      this.setSkillsList();
    });
    // Start walkthrough.
    this.startWalkthrough();
    // Set Form Default Values.
    //this.resetForm();
    this.setFormValues();

  }

  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', { name: 'activity-list', data: this.navParams.data });
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param item     Parameter to process in action.
  */
  process(action: string, item?: any) {
    switch (action) {
      case 'view-activity':
        this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: item.id })]);
        return;
      case 'create-activity':
        this.router.navigate(['/ActivityEditPage', '{}']);
        return;
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }

  /** 
  * Open skill detail.
  * @param item
  */
  openActivityDetail(item: any) {
    this.courseService.checkUserIsBooked(item.id).then(res => {
      console.log('res', res)
      if (res && res.course_id == item.id) {
        this.router.navigate(['/CourseDetailPage', JSON.stringify({ courseID: item.id, isBooked: res.is_booked, onWaitingList: res.on_waiting_list })]);
      } else {
        this.router.navigate(['/CourseDetailPage', JSON.stringify({ courseID: item.id, isBooked: false, onWaitingList: false })]);
      }
    });
  }

  // Set selected tab
  setSelectedTab(tabName) {
    this.tabSelection$.next(tabName);
  }

  // Set Default Form Values.
  setDefaultFormValues() {

    let defaultOrganisations = [];
    this.organisations.forEach((organisation) => {
      defaultOrganisations.push(organisation.id)
    })
    let defaultAttendance = [];
    this.attendanceTypes.forEach((attendance) => {
      defaultAttendance.push(attendance.key)
    })
    let defaultSkills = [];

    this.isDirtyForm = false;
    this.skills.forEach((skill) => {
      defaultSkills.push(skill.id)
    });


    // Set form controls.
    this.filterForm.controls.organisations.setValue(defaultOrganisations);
    this.filterForm.controls.attendanceType.setValue(defaultAttendance);
    this.filterForm.controls.skills.setValue(defaultSkills);

    const nowTimestamp = Math.floor(MyUtil.getUnixTimeStamp() / 86400) * 86400;
    // let defaultStart = (this.routeData.start_at) ? MyUtil.formatUnixTimeStamp2ISO(this.routeData.start_at * 1000) : MyUtil.formatUnixTimeStamp2ISO(MyUtil.getUnixTimeStamp() - (86400 * 60));
    let defaultStart = (this.routeData.start_at) ? MyUtil.formatUnixTimeStamp2ISO(this.routeData.start_at * 1000) : MyUtil.formatUnixTimeStamp2ISO(nowTimestamp);
    let defaultEnd = MyUtil.formatUnixTimeStamp2ISO(MyUtil.getUnixTimeStamp() + (86400 * 365 * 75));
    this.filterForm.controls.start.setValue(defaultStart);
    this.filterForm.controls.end.setValue(defaultEnd);

  }

  setDefaultFormValuesFromRouteData() {

    let defaultOrganisations = [];
    this.organisations.forEach((organisation) => {
      defaultOrganisations.push(organisation.id)
    })
    let defaultAttendance = [];
    this.attendanceTypes.forEach((attendance) => {
      defaultAttendance.push(attendance.key)
    })
    let defaultSkills = [];
    if (this.routeData.skills.length > 0) {
      this.isDirtyForm = true;
      defaultSkills = this.routeData.skills;
    }
    // Set form controls.
    this.filterForm.controls.organisations.setValue(defaultOrganisations);
    this.filterForm.controls.attendanceType.setValue(defaultAttendance);
    this.filterForm.controls.skills.setValue(defaultSkills);

    const nowTimestamp = Math.floor(MyUtil.getUnixTimeStamp() / 86400) * 86400;
    // let defaultStart = (this.routeData.start_at) ? MyUtil.formatUnixTimeStamp2ISO(this.routeData.start_at * 1000) : MyUtil.formatUnixTimeStamp2ISO(MyUtil.getUnixTimeStamp() - (86400 * 60));
    let defaultStart = (this.routeData.start_at) ? MyUtil.formatUnixTimeStamp2ISO(this.routeData.start_at * 1000) : MyUtil.formatUnixTimeStamp2ISO(nowTimestamp);
    let defaultEnd = MyUtil.formatUnixTimeStamp2ISO(MyUtil.getUnixTimeStamp() + (86400 * 365 * 3));
    this.filterForm.controls.start.setValue(defaultStart);
    this.filterForm.controls.end.setValue(defaultEnd);

  }

  setDirtyFormValues() {
    this.isDirtyForm = true;
    this.filterForm.controls.searchTerm.setValue(this.findActivitiesFilters.searchTerm);
    this.filterForm.controls.organisations.setValue(this.findActivitiesFilters.organisations);
    this.filterForm.controls.attendanceType.setValue(this.findActivitiesFilters.attendanceType);
    this.filterForm.controls.skills.setValue(this.findActivitiesFilters.skills);
    this.filterForm.controls.start.setValue(this.findActivitiesFilters.start);
    this.filterForm.controls.end.setValue(this.findActivitiesFilters.end);
  }

  setFormValues() {

    switch (true) {
      case !MyUtil.lodash.isEmpty(this.routeData):
        this.setDefaultFormValuesFromRouteData();
        break;

      case !this.findActivitiesFilters:
        this.setDefaultFormValues();
        break;

      default:
        this.setDirtyFormValues();
        break;
    }

  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  

  // Check if is part of program.
  isPartOfProgram(programIds) {
    return function (element) {
      let contains = false;
      if (element.programs.length > 0) {
        element.programs.forEach(element => {
          if (programIds.indexOf(element) > -1) {
            contains = true;
          }
        });
      } else {
        contains = true;
      }
      return contains;
    }
  }
}