import { Injectable } from '@angular/core';
import { NgxCopilotService } from 'ngx-copilot';
import { MyUtil } from 'src/libs/MyUtil';

@Injectable({
  providedIn: 'root'
})
export class WalkthroughService {

  constructor(
    private  copilot:  NgxCopilotService,
  ) { }

  static getCurrentWalkthroughState(){
    let walkthrough = MyUtil.retrieveFromLocalStorage('walkthrough');
    if (walkthrough !== null && walkthrough !== undefined && walkthrough !== "undefined"){
      return walkthrough;
    }else{
      MyUtil.saveToLocalStorage('walkthrough', '{"pages":[]}');
      walkthrough = MyUtil.retrieveFromLocalStorage('walkthrough');
      return walkthrough;
    }
  }
  
  static allWalkthroughsDisabled(){
    var profile = MyUtil.getProfile();
    return profile?.hide_walkthrough;
  }

  static isWalkthroughComplete(pageName:string){
    
    // check if disabled at org level first (this is a quick fix for RSC)
    var profile = MyUtil.getProfile();
    
    if(profile?.hide_walkthrough) {
      return true;
    }
    
    // loop through current set of saved pages state from local storage
    // if the page name matches and complete === 1 return true
    var result = false;
    let currentWalkthroughState = this.getCurrentWalkthroughState();
    if(!currentWalkthroughState){
      return result;
    }
    JSON.parse(currentWalkthroughState).pages.forEach(page => {
      if(page.pageName === pageName){
        result =  page.complete;
      }
    });
    return result;
  }
  
  static resetAllWalkthroughs(){
    MyUtil.saveToLocalStorage('walkthrough', '{"pages":[]}');
  }

  static setWalkthroughState(pageName:string, value:boolean){
    // find page (if not exists create)
    let currentState = JSON.parse(this.getCurrentWalkthroughState());
    currentState.pages = currentState.pages.filter(item=>{
      return item.pageName !== pageName
    })
    currentState.pages.push({"pageName": pageName, "complete": value })
    MyUtil.saveToLocalStorage('walkthrough', JSON.stringify(currentState));
  }

}

export interface WalkthroughProgress{
  pages: WalkthroughPage[];
}
export interface WalkthroughPage{
  pageName:string;
  complete: boolean;
}
