import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Appapi } from 'src/app/providers/appapi';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { MyUtil } from '../../../libs/MyUtil';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'page-email-settings',
  templateUrl: 'email-settings.html',
})
export class EmailSettingsPage {
  pageData: any = {};

  constructor(public router: Router, public navParams: NavParams, public formBuilder: UntypedFormBuilder,
    public appapi: Appapi, public desktopChecker: DesktopChecker,
    private titleService:Title) {
      this.titleService.setTitle("Email Settings");
      this.pageData.digestPref = -1;
      this.pageData.bookingPref = -1;
      if (MyUtil.isNetworkConnected()) {
        let loading = MyUtil.presentLoading();
        this.appapi.get('/load-digest', []).then(async result => {
          if (result['#status'] === 'success') {
            (await loading).dismiss();
            this.pageData.digestPref = result['#data']['status'];
            this.pageData.bookingPref = result['#data']['booking'];
          } else {
            (await loading).dismiss();
            MyUtil.presentToast(MyUtil.responseToMessage(result['#message'], result['#errors']), { cssClass: 'inkpath-toast' });
          }
        }).catch(async err => {
          MyUtil.error(err);
          (await loading).dismiss();
          MyUtil.presentToast('Oops! Service is not available. Please try again later.', { cssClass: 'inkpath-toast' });
        });
      } else {
        MyUtil.presentToast('Please check the network connection and try again later.', { cssClass: 'inkpath-toast' });
      }
  }

  /** 
  * Process.
  */
  process() {
    if (MyUtil.isNetworkConnected()) {
      let loading = MyUtil.presentLoading();
      let data = {
        "digest": this.pageData.digestPref,
        "booking": this.pageData.bookingPref
      };

      this.appapi.post('/save-digest', data).then(async result => {
        if (result['#status'] === 'success') {
          (await loading).dismiss();
          MyUtil.presentToast(result['#message']);
        } else {
          (await loading).dismiss();
          MyUtil.presentToast(MyUtil.responseToMessage(result['#message'], result['#errors']), { cssClass: 'inkpath-toast' });
        }
      }).catch(async err => {
        MyUtil.error(err);
        (await loading).dismiss();
        MyUtil.presentToast('Oops! Service is not available. Please try again later.', { cssClass: 'inkpath-toast' });
      });
    } else {
      MyUtil.presentToast('Please check the network connection and try again later.', { cssClass: 'inkpath-toast' });
    }
  }
}
