import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { WalkthroughService } from 'src/app/services/walkthrough.service';
import { getBrandName } from 'src/app/store/selectors/view.selector';
import * as appStore from '../../store';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit, OnDestroy{

  constructor(
    private store: Store<appStore.AppViewState>
  ) { }
  
  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  /**
  * Get brand name from store.
  */
  brandName$: Observable<string> = this.store.select(getBrandName);

  // Inputs
  @Input() title: string;
  @Input() mobileTitle: string;
  @Input() walkthroughLabel: string;
  @Input() showHelpIcon: boolean;
  @Output() startWalkthrough:EventEmitter<boolean> = new EventEmitter();

  /**
  * Start walkthrough handler.
  */
  startWalkthroughHandler(){
    WalkthroughService.setWalkthroughState(this.walkthroughLabel, false);
    this.startWalkthrough.emit(true);
  }

  ngOnInit() {}

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
