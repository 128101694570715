import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccessibilityModalComponent } from 'src/app/components/accessibility-modal/accessibility-modal.component';
import { ContactModalComponent } from 'src/app/components/contact-modal/contact-modal.component';
import { Appapi } from 'src/app/providers/appapi';
import { SwitchOrgModal } from "src/app/components/switch-org-modal/switch-org-modal";
import { AuthProvider } from 'src/app/providers/auth/auth';
import { SsoLoginProvider } from 'src/app/providers/sso-login/sso-login';
import { getBrandName, getDisplayMode, getUsername } from 'src/app/store/selectors/view.selector';
import { MyUtil } from 'src/libs/MyUtil';
import * as appStore from '../../store';
import { MyDb } from 'src/libs/MyDb';

declare var window: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent implements OnInit, OnDestroy {

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Component variables.
  pageData: any = {};
  displayMode$: Observable<string> = this.store.select(getDisplayMode);
  branding = null;
  brandName$: Observable<string> = this.store.select(getBrandName);
  username$: Observable<string> = this.store.select(getUsername);
  profileDisabled = false;
  activeAccountMenu = false;

  // menuOpened() {
  //   setTimeout(() => {
  //     let accountButton = document.getElementById('switch-org');
  //     if(accountButton){
  //       accountButton.focus();
  //     }
  //   }, 0);
  // }

  constructor(
    private router: Router,
    public appapi: Appapi,
    public auth: AuthProvider,
    public ssoLogin: SsoLoginProvider,
    private store: Store<appStore.AppViewState>,
  ) { }

  /** 
  * Set display mode.
  * @param mode   mode: string.
  */
  setDisplayMode(mode: string) {
    this.store.dispatch(new appStore.DisplayModeSet(mode));
    MyUtil.saveToLocalStorage('displayMode', mode);
  }

  /** 
  * Toggle Account Menu.
  */
  toggleAccountMenuClick(event) {
    this.activeAccountMenu = !this.activeAccountMenu;
    //this.menuOpened();
  }

  /** 
  * Check profile complete.
  */
  checkProfileComplete() {
    return (MyUtil.validateProfile()) ? true : false;
  }

  ngOnInit() {

    this.brandName$.pipe(takeUntil(this.destroy$)).subscribe(brandName => {
      this.branding = brandName;
    })

    this.pageData.enable_recommendations = false;
    this.appapi.syncUserProfile().then(() => this.appapi.syncOrganization()).then(() => {
      this.pageData.initialisedProfile = MyUtil.getProfile();

      this.pageData.alternativeProfilesData = [];
      this.appapi.getAlternativeProfilesData().then((alternativeProfilesData) => {
        this.pageData.alternativeProfilesData = alternativeProfilesData;
      });

      // check if recommendations are active - hide next steps if not
      this.pageData.orgSettings = this.pageData.initialisedProfile.orgSettings;
      if (this.pageData.orgSettings.enable_recommendations && this.pageData.orgSettings.enable_recommendations === 1) {
        this.pageData.enable_recommendations = true;
      }

      this.pageData.profileNameLabel = '';
      if (this.pageData.initialisedProfile.first_name && this.pageData.initialisedProfile.last_name) {
        this.pageData.profileNameLabel = this.pageData.initialisedProfile.first_name + ' ' + this.pageData.initialisedProfile.last_name;
      }
      if (this.branding == 'inkpath') {
        if (this.pageData.profileNameLabel !== '') {
          this.pageData.profileNameLabel += ' @ '
        }
        this.pageData.profileNameLabel += this.pageData.orgSettings.org_name;
      }

      this.store.dispatch(new appStore.usernameSet(this.pageData.profileNameLabel));

      this.pageData.showNextSteps = false;
    })
  }

  /** 
  * Contact click handler.
  */
  public async contactClickHandler() {
    let contactModal = MyUtil.createModal(ContactModalComponent, {});
    (await contactModal).onDidDismiss().then((data: any) => { });
    (await contactModal).present();
  }

  /** 
  * Actions.
  * @param actionItem   Name of action (string).
  */
  async process(actionItem) {
    this.store.dispatch(new appStore.MenuStateSet('inactive-mobile-menu'));
    this.store.dispatch(new appStore.FindActivitiesFilterStateSet(undefined));

    if (MyUtil.isNetworkConnected() || actionItem == 'about-app') {

      switch (actionItem) {
        case 'activities':
          this.router.navigate(['/']);
          break;
        case 'account':
          this.router.navigate(['/UserSettingsPage', '{}']);
          break;
        case 'about-app':
          this.router.navigate(['/AboutPage']);
          break;
        case 'show-website':
          this.appapi.openBrowser('https://www.inkpath.co.uk/');
          break;
        case 'recommended':
          this.router.navigate(['/RecommendedComponent']);
          break;
        case 'goals-page':
          this.router.navigate(['/GoalsPage']);
          break;
        case 'user-page':
          this.router.navigate(['/UserPage']);
          break;
        case 'show-website':
          this.appapi.openBrowser('https://www.inkpath.co.uk/');
          break;
        case 'terms-of-use':
          this.appapi.openAppLawBrowser('terms_and_conditions');
          break;
        case 'privacy-policy':
          this.appapi.openAppLawBrowser('privacy_policy');
          break;
        case 'open-help':
          
        await  MyDb.appLoad(MyUtil.DOC_ID.APP_USER).then(appUser =>{
          this.appapi.post('/zendesk/ssotoken/' + appUser.id, {}).then(async (result) => {
              window.open(result['#data'].sso_url, "_blank");
            }).catch(async err => {
              MyUtil.error(err);
            });
          });
          break;
        case 'open-help-rsc':
          this.appapi.openBrowser('https://members.rsc.org/site/content/PathfinderHelp.aspx');
          break;
        case 'email-settings':
          this.router.navigate(['/EmailSettingsPage']);
          break;
        case 'open-accessibility':
          this.accessibilityHandler();
          break;
        case 'change-password':
          this.router.navigate(['/PasswordPage', JSON.stringify({ 'initialisedProfile': this.pageData.initialisedProfile })]).catch(err => {
            MyUtil.debug(['navigate', err]);
          });
          break;
        case 'diagnostic':
          this.router.navigate(['/DiagnosticListPage', '']);
          break;
        case 'funding-list':
          this.router.navigate(['/FundingListPage', JSON.stringify({ 'initialisedProfile': this.pageData.initialisedProfile })]).catch(err => {
            MyUtil.debug(['navigate', err]);
          });
          break;
        case 'switch-org':
          this.showSwitchOrgModal();
          break;
        case 'logout':
          this.auth.logout();
          break;
        default:
          break;
      }
    } else {
      MyUtil.presentToast('Please check the network connection and try again later.', { cssClass: 'inkpath-toast' });
    }
  }

  /** 
  * Show switch organisation modal.
  */
  public async showSwitchOrgModal() {
    if (!MyUtil.isNetworkConnected()) {
      let toast = MyUtil.presentToast(
        "Please connect to the internet and try again.", { cssClass: 'inkpath-toast' }
      );
      return;
    }

    //Refresh data
    this.appapi.getAlternativeProfilesData().then(async (alternativeProfilesData) => {
      this.pageData.alternativeProfilesData = alternativeProfilesData;

      let switchOrgModal = MyUtil.createModal(SwitchOrgModal, {
        title: "Switch Organisation",
        orgName: this.pageData.orgSettings.org_name,
        buttons: [
          {
            text: "Switch",
          },
          {
            text: "Cancel",
          },
        ],
        alternativeProfilesData: this.pageData.alternativeProfilesData,
      });

      (await switchOrgModal).onDidDismiss().then((alternativeOid: any) => {
        if (alternativeOid.data) {
          this.switchOrg(alternativeOid.data, true);
        }
      });

      (await switchOrgModal).present();
    });
  }

  /**
   * Switches the login use use a profile that matches the selected org.
   * @param newOid
   */
  switchOrg(newOid: number, modal: boolean = false): Promise<boolean> {

    return this.appapi.post('/switch-org', { newOid: newOid }).then(result => {

      if (result['#status'] === 'success') {

        this.appapi.forgetAppUser(true).then(() => {

          let userData = result['#data'];
          MyUtil.saveToLocalStorage('localOid', userData.oid);

          if (userData.sso) {
            //Redirect user via SSO
            const organization = MyUtil.cache[MyUtil.DOC_ID.APP_UNIVERSITIES][userData.oid];
            this.ssoLogin.initializeSsoLogin(organization);
          } else {
            //Log user in
            this.auth.loginUser(result['#data'], true);
          }
        });

        return true;

      } else {
        if (modal) {
          MyUtil.presentToast(result['#message'], { cssClass: 'inkpath-toast' });
        }
        return false;
      }
    }).catch(err => {
      if (modal) {
        MyUtil.presentToast('An error occurred switching organisations. Please try again later.', { cssClass: 'inkpath-toast' });
      }
      return false;
    });
  }

  /** 
  * Accessibility handler.
  */
  public async accessibilityHandler() {
    let accessibilityModalComponent = MyUtil.createModal(AccessibilityModalComponent, {});
    (await accessibilityModalComponent).onDidDismiss().then((data: any) => { });
    (await accessibilityModalComponent).present();
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
