import { Injectable } from '@angular/core';
import { CoursesService } from '../courses-service';

import { Router } from '@angular/router';
import { MyDb } from 'src/libs/MyDb';
import { MyUtil } from 'src/libs/MyUtil';
import { Appapi } from '../appapi';

@Injectable({
  providedIn: 'root',
})
export class RedirectProvider {
  readonly REDIRECT_TARGET = 'REDIRECT_TARGET';

  constructor(public courseService:CoursesService, public appApi: Appapi, public router: Router) {

  }

  setRedirectTarget(data: any) {

    console.log('data data data data', data)
    try {
      MyUtil.debug(data);
      if (data) {

        let target: any = atob(data);
        target = JSON.parse(target);
        MyUtil.debug(target);
       // console.log('target', target)
    
        // handle login
        if (target && target.params && target.name) {
          if(target.name == 'login') {
            let appUser: any = { _id: MyUtil.DOC_ID.APP_USER };
            MyUtil.lodash.merge(appUser, target.params);
            MyDb.appSave(appUser).then(userDoc => {
              this.reloadApp();
            }).catch(err => {
              MyUtil.error(['Login failed for redirect user', err]);
            });
          } else {
            MyUtil.saveToLocalStorage(this.REDIRECT_TARGET, JSON.stringify(target));
            //Only redirect if the local database exists - it won't at this point if page redirect link pasted into a new tab (don't know why - something to do with page loading logic)
            //Try loading something from local db and catch the error that occurs if it doesn't exist, then do nothing and let app.components.ts handle the request
            //It's a hack but it works
            MyDb.userLoad(MyUtil.DOC_ID.USER_PROFILE).then((doc: any) => {
              this.router.navigate(['/']);
            }).catch();
          }
        } else {
          // fail to get redirect target
          MyUtil.debug('Redirect target name not found');
        }
      } else {
        // fail to get redirect target
        MyUtil.debug('Redirect target not found');
      }
    } catch (err) {
      MyUtil.error(['Failed to set redirect target', err]);
    }
  }

  private reloadApp() {
    let href: string = window.location.href;
    let idx:any = href.indexOf('#/redirect');

    if (idx !== false) {
      href = href.substr(0,idx);
    } else {
      href = "/";
    }

    MyUtil.debug(['reload app', href]);

    window.location.href = href;
  }

  processRedirectTarget(router: Router, options: any) {
    try {
      let rawTarget = MyUtil.removeFromLocalStorage(this.REDIRECT_TARGET);
      MyUtil.debug(rawTarget);

      if (rawTarget) {
        let target: any = JSON.parse(rawTarget);
        MyUtil.debug(target);

        // process the target
        switch (target.name) {
          case 'ResetPasswordComponent':
            router.navigate(['/SetPasswordComponent', JSON.stringify(target)]);
            break;
          case 'SetPasswordComponent':
          
            router.navigate(['/SetPasswordComponent', JSON.stringify(target)]);
            break;

          case 'ActivityDetailPage':
            this.appApi.syncAllActivities().then((res) => {
              if (target.params && MyUtil.getActivity(target.params.id)) {
                //router.navigate(['/ActivityListPage']);
                router.navigate(['/ActivityDetailPage', JSON.stringify(target.params)]);
              } else {
                MyUtil.presentToast('Failed to match the activity. If you are signed up to multiple Inkpath organisations, please ensure you are logged into the correct one.', { cssClass: 'inkpath-toast' });
              }
            });

            break;
          case 'CourseDetailPage':
            if (target.params && target.params.id) {
              this.courseService.getCourseDetails(target.params.id).then(res=>{

                this.courseService.checkUserIsBooked(target.params.id).then(csres => {
                  //router.navigate(['/ActivityListPage']);
                  if(csres && csres.course_id == target.params.id) {
                    router.navigate(['/CourseDetailPage', JSON.stringify({ courseID: target.params.id, isBooked: csres.is_booked, onWaitingList: csres.on_waiting_list })]);
                  }else{
                    router.navigate(['/CourseDetailPage', JSON.stringify({ courseID: target.params.id, isBooked: false, onWaitingList: false })]);
                  }
                });

              });
            } else {
              MyUtil.presentToast('Failed to match the course. If you are signed up to multiple Inkpath organisations, please ensure you are logged into the correct one', { cssClass: 'inkpath-toast' });
            }
            break;
          case 'SkillDetailsPage':
            if (target.params && MyUtil.getSkill(target.params.id)) {
              // navCtrl.setRoot(UserPage);
              router.navigate(['/SkillDetailsPage', JSON.stringify(target.params)]);
            } else {
              MyUtil.presentToast('Failed to match the skill. If you are signed up to multiple Inkpath organisations, please ensure you are logged into the correct one', { cssClass: 'inkpath-toast' });
            }
            break;
          case 'EmailSettingsPage':
              // navCtrl.setRoot(UserPage);
              router.navigate(['/EmailSettingsPage', JSON.stringify(target.params)]);
              break;
          default:
            MyUtil.debug('Unknown target name ' + target.name);
            break;
        }
      } else {
        // fail to get redirect target
        MyUtil.debug('Redirect target not found');
      }
    } catch (err) {
      MyUtil.error(['Fail to get redirect target', err]);
    }

    return false;
  }
}
