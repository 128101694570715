// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  DEBUG: false,
  APP_VERSION: "2.1.006 Production",
  API_SERVER_URL: "https://web.preprod.inkpath.co.uk",
  API_PATH: "/api/v1",
  APP_DB_NAME: "inkpath",
  RSC_URL: "pathfinder.rsc.org",
  RSC_AUTH_TOKEN: "OSw3YkhtrwbU9u78rNTLnCdLeXHeZK5uDETbfuVFa2jzTkXMM5BSi7ntqgPTSRLs",
  RSC_RESET_URL: "https://members.rsc.org/site/content/Update_password.aspx"
};

var pattern = encodeURI(window.location.host);

var cssurl = environment.API_SERVER_URL + environment.API_PATH + '/branding/css?pattern=' + pattern + '&ts=' + Date.now();
var csselement=document.createElement("link")
csselement.setAttribute("rel", "stylesheet")
csselement.setAttribute("type", "text/css")
csselement.setAttribute("href", cssurl)
document.getElementsByTagName("head")[0].appendChild(csselement)
  // appending customised branding favicon
 // var brandingName = environment.API_SERVER_URL + environment.API_PATH + '/branding/name?pattern=' + pattern;
  


 // console.log('server stuff', brandingName)
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
  


  // appending customised branding favicon
//   var faviconurl = environment.API_SERVER_URL + environment.API_PATH + '/branding/favicon?pattern=' + pattern;
//   if(faviconurl) {
//    document.getElementById('appFavicon').setAttribute('href', faviconurl);
//  }

  //appending customised branding script
  var scripturl = environment.API_SERVER_URL + environment.API_PATH + '/branding/js?pattern=' + pattern + '&ts=' + Date.now();
  var scriptelement =document.createElement('script')
  scriptelement.setAttribute("type","text/javascript")
  scriptelement.setAttribute("src", scripturl)
  document.getElementsByTagName("head")[0].appendChild(scriptelement)

  //appending customised branding css
  // var cssurl = environment.API_SERVER_URL + environment.API_PATH + '/branding/css?pattern=' + pattern + '&ts=' + Date.now();
  // var csselement=document.createElement("link")
  // csselement.setAttribute("rel", "stylesheet")
  // csselement.setAttribute("type", "text/css")
  // csselement.setAttribute("href", cssurl)
  // document.getElementsByTagName("head")[0].appendChild(csselement)
