import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { Appapi } from 'src/app/providers/appapi';
import { getBrandName } from 'src/app/store/selectors/view.selector';
import { MyUtil } from 'src/libs/MyUtil';
import * as appStore from '../../store';

@Component({
  selector: 'app-contact-modal',
  templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.scss'],
})
export class ContactModalComponent implements OnInit {

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  // Component variables.
  showContact = false;
  fundingOrgs: any;
  brandName$: Observable<string> = this.store.select(getBrandName);
  branding = null;
  profileOrg = {
    public_contact_email: '',
    name: '',
    id: ''
  };

  constructor(
    public appapi: Appapi,
    public viewCtrl: ModalController,
    private store: Store<appStore.AppViewState>,
  ) { }

  ngOnInit() {
    this.appapi.syncUserProfile().then(() => this.appapi.syncOrganization()).then(async () => {
      let profile: any = MyUtil.getProfile();
      let oid = profile.oid;
      this.profileOrg = MyUtil.getRootOrganization(oid);
      this.profileOrg['mailString'] = `mailto:${this.profileOrg.public_contact_email}`;
      this.fundingOrgs = Object.values(MyUtil.cache[MyUtil.DOC_ID.USER_FUNDING_ORGANIZATIONS]).filter((item) => {
        return (item !== undefined && item['name'] && !item['oid'] && item['id'] != this.profileOrg.id)
      }).map((item, key) => {
          return {
            name: item['name'],
            public_contact_email: item['public_contact_email'],
            mailString: `mailto:${item['public_contact_email']}`
          }
      });
    })
  }

  // Close modal.
  closeModal() {
    this.viewCtrl.dismiss();
  }
}
