import { Component, NgZone, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { ActivityService } from 'src/app/providers/activity-service';
import { Appapi } from 'src/app/providers/appapi';
import { CoursesService } from 'src/app/providers/courses-service';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { MyUtil } from '../../../libs/MyUtil';

//NO LONGER USED?

@Component({
  selector: 'page-courses-list',
  templateUrl: 'courses-list.html'
})
export class CoursesListPage {
  @ViewChild('Content', {static: true}) content;
  pageData: any = {};
  courseList =[];
  courseListDisplay=[];

  constructor(public router: Router, public navParams: NavParams, public zone: NgZone,
    public appapi: Appapi, public desktopChecker: DesktopChecker, public activityService: ActivityService, public service: CoursesService) {
    // expand help for first time landing on the page
    if (MyUtil.context.helpStatus[MyUtil.HELP_ID.ACTIVITY_LIST]) {
      this.pageData.helperToggle = false;
    } else {
      this.pageData.helperToggle = true;
      this.appapi.setAppHelpStatus(MyUtil.HELP_ID.ACTIVITY_LIST, true);
    }

    this.pageData.isMobileAppMode = MyUtil.isMobileAppMode();
    this.pageData.segment = 'upcoming';
    let preDefinedFilters = this.navParams.data;
    this.pageData.activitiesDisplay = [];
    this.pageData.filter = {
      searchKey: '',
      skills: (preDefinedFilters.skills) ? preDefinedFilters.skills : null,
      organizations: MyUtil.getOrgnizationChain(),
      attendance_type: MyUtil.getAttendanceTypeChain(),
      startAt: (preDefinedFilters.start_at) ? MyUtil.formatUnixTimeStamp2ISO(preDefinedFilters.start_at * 1000) : MyUtil.formatUnixTimeStamp2ISO(MyUtil.getUnixTimeStamp() - 86400 * 60),
      endAt: MyUtil.formatUnixTimeStamp2ISO(MyUtil.getUnixTimeStamp() + 86400 * 365 * 3),
      dateMin: MyUtil.formatUnixTimeStamp2ISO(MyUtil.getUnixTimeStamp() - 86400 * 365 * 5),
      dateMax: MyUtil.formatUnixTimeStamp2ISO(MyUtil.getUnixTimeStamp() + 86400 * 365 * 3),
    };
    this.pageData.filterDisplay = {};
    this.pageData.selectedActivities = {};
    this.pageData.bookingStatus = {};

    this.fillCoursesList();
  }


  fillCoursesList(){

    this.courseList =[];

    this.service.getAvailableCourses().then((res) => {
         Object.keys(res.data).forEach((prop)=> {
            this.courseList.push(res.data[prop]);

          }
       );
     }).catch((err) => {

   });
   this.courseListDisplay = this.courseList;
  }


  ionViewDidLoad() {
    MyUtil.firebaseSetScreenName('courses-list');
  }

  ionViewDidEnter() {
    MyUtil.firebaseLogEvent('view_did_enter', { name: 'activity-list', data: this.navParams.data });

    this.refreshUserActivities();

    this.activityService.listBookingStatus().then(result => {
      this.pageData.bookingStatus = result;
    });

    MyUtil.subscribeEvent(MyUtil.EVENT.APP_APPLY_FILTER, () => {
      this.applyFilter();
    });
  }

  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', { name: 'course-list', data: this.navParams.data });

    MyUtil.unsubscribeEvent(MyUtil.EVENT.APP_APPLY_FILTER);
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param item     Parameter to process in action.
  */
  process(action: string, item?: any) {
    // Trick to avoid ionChange fired twice, @TODO remove when ionic fix this.
    let changedTimestamp = MyUtil.getUnixTimeStamp();

    switch (action) {
      case 'open-course-details':
        this.router.navigate(['/CourseDetailPage', { CourseDetails: this.courseList[item] , isBooked: false}]);
        return;

      case 'add-activity':
        this.addActivity(item);
        return;

      case 'remove-activity':
        this.removeActivity(item);
        return;

      case 'search-key':
        this.pageData.filter.toggle = false;
        this.applyFilter();
        return;

      case 'search-skill':
        this.showSkillCheckbox('Choose Skills', this.pageData.filter.skills);
        return;

      case 'search-organizations':
        this.showOrganizationCheckbox();
        return;

      case 'search-attendance-type':
        this.showAttendanceTypeCheckbox();
        return;

      case 'search-start':
        // trick to avoid ionChange fired twice, @TODO remove when ionic fix this
        if (this.pageData.changedTimestamp && this.pageData.changedTimestamp >= changedTimestamp - 1) {
          return;
        }
        this.pageData.changedTimestamp = changedTimestamp;

        let sts1 = MyUtil.getUnixTimeStamp(this.pageData.filter.startAt);
        let sts2 = MyUtil.getUnixTimeStamp(this.pageData.filter.endAt);
        if (sts1 > sts2) {
          this.pageData.filter.endAt = this.pageData.filter.startAt
        }
        this.applyFilter();
        return;

      case 'search-end':
        // trick to avoid ionChange fired twice, @TODO remove when ionic fix this
        if (this.pageData.changedTimestamp && this.pageData.changedTimestamp >= changedTimestamp - 1) {
          return;
        }
        this.pageData.changedTimestamp = changedTimestamp;

        let ets1 = MyUtil.getUnixTimeStamp(this.pageData.filter.startAt);
        let ets2 = MyUtil.getUnixTimeStamp(this.pageData.filter.endAt);
        if (ets1 > ets2) {
          this.pageData.filter.startAt = this.pageData.filter.endAt
        }
        this.applyFilter();
        return;

      case 'create-activity':
        this.router.navigate(['/ActivityEditPage', {}]);
        return;

      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }

  scrollHandler(event) {
    this.zone.run(() => {
      //close filter when scrolling
      if (this.pageData.filter.toggle && event && event.scrollTop > 280) {
        this.pageData.filter.toggle = false;
      }
      if (event && event.scrollTop > 40) {
        MyUtil.publishEvent(MyUtil.EVENT.APP_TOGGLE_HELP, false);
      }
    })
  }

  segmentChanged(event) {
    setTimeout(() => {
      MyUtil.publishEvent(MyUtil.EVENT.APP_APPLY_FILTER);
    }, 10);

    this.zone.run(() => {
      this.pageData.activitiesDisplay = [];
    });
  }

  private addActivity(id) {
    this.activityService.addToUserActivity(id).then(() => {
      this.refreshUserActivities();
    });
  }

  private removeActivity(id) {
    this.activityService.queryUserActivity(id).then((doc) => {
      let userActivityDoc = doc;

      if (!userActivityDoc || userActivityDoc.delete) {
        MyUtil.presentToast('Activity is not in personal list', { cssClass: 'inkpath-toast' });
        return;
      }

      if (userActivityDoc.data.status == MyUtil.CONST.APP_META.USER_ACTIVITY_STATUS_DONE) {
        MyUtil.presentAlert({
          title: 'Are you sure?',
          message: 'This activity has been marked as complete. Are you sure you wish to delete this activity?',
          buttons: [
            {
              text: 'Cancel',
              handler: () => {
                return;
              }

            },
            {
              text: 'Confirm',
              handler: () => {
                this.deleteActivity(userActivityDoc);
              }
            }
          ],
        });
      } else {
        this.deleteActivity(userActivityDoc);
      }
    });
  }

  private deleteActivity(userActivityDoc: any): void {
    this.activityService.deleteFromUserActivity(userActivityDoc).then(() => {
      this.refreshUserActivities();
    });
  }

  // reload all user activities data
  private refreshUserActivities() {
    this.appapi.queryUserActivity().then((result) => {
      this.pageData.selectedActivities = MyUtil.lodash.keyBy(result, 'activity_id');
      this.applyFilter();
    });
  }

  private renderFilter() {
    this.pageData.filterDisplay = {
      organizations: MyUtil.lodash.chain(this.pageData.filter.organizations).filter((item) => {
        return !item.hidden;
      }).map('name').value().join(','),
      skills: MyUtil.lodash.chain(MyUtil.getSkills()).filter((item) => {
        return (this.pageData.filter.skills && this.pageData.filter.skills.length > 0 && this.pageData.filter.skills.indexOf(parseInt(item.id)) !== -1);
      }).map('name').value().join(','),
      attendance_type: MyUtil.lodash.chain(this.pageData.filter.attendance_type).filter((item) => {
        return !item.hidden;
      }).map('value').value().join(','),
    };

    if (!this.pageData.filterDisplay.skills) {
      this.pageData.filterDisplay.skills = '(all)';
    }

    if (!this.pageData.filterDisplay.attendance_type) {
      this.pageData.filterDisplay.attendance_type = '(all types)';
    }
  }

  private applyFilter() {
    this.renderFilter();

    this.courseListDisplay = this.courseList;
    //check if search key is set to a valid inpt, then filter the base course list into the corse display, otherwise set it to base course list
    if(/^[a-zA-Z]+$/.test(this.pageData.filter.searchKey)){
      this.courseListDisplay = this.courseList.filter(this.isSearchKey(this.pageData.filter.searchKey));
    } else{
      this.courseListDisplay = this.courseList;
    }

    if(this.pageData.filter.organizations.length>0){
      this.courseListDisplay = this.courseListDisplay.filter(this.isProvidedbyOrganisation(this.pageData.filter.organizations));
    }


    if(this.pageData.filter.organizations.length>0){
      this.courseListDisplay = this.courseListDisplay.filter(this.isProvidedbyOrganisation(this.pageData.filter.organizations));
    }

   if(this.pageData.filter.startAt) {

     this.courseListDisplay = this.courseListDisplay.filter(this.isAfterStartDate(MyUtil.getUnixTimeStamp(this.pageData.filter.startAt)));

    }

    if(this.pageData.filter.endAt) {

      this.courseListDisplay = this.courseListDisplay.filter(this.isBeforeEndDate(MyUtil.getUnixTimeStamp(this.pageData.filter.endAt)));


     }


  }


  private isSearchKey(key:string) {

    return function(element) {

    if(element.name.toLowerCase().includes(key.toLowerCase())){
      return true;
    }else{
      return false;
    }
  }

 }



 private isProvidedbyOrganisation(orgArray:any[]) {

  return function(element) {
      var or:boolean = false;
    orgArray.forEach(item=>{
     if(element.oid == item.id){
      or = true;
     }
    });

    return or;
  }

}

private isAfterStartDate(startDate:any) {

  return function(element) {
    if(element.start_at>startDate)return true;
    else return false;
  }

}

private isBeforeEndDate(endDate:any) {

  return function(element) {
    if(element.end_at<endDate)return true;
    else return false;
  }

}







  private showOrganizationCheckbox() {
    let inputs = MyUtil.lodash.chain(this.pageData.filter.organizations).map((item) => {
      return {
        type: 'checkbox',
        label: item.name,
        value: item.id,
        checked: !item.hidden,
      };
    }).value();

    MyUtil.presentAlert({
      'title': 'Choose organisations to search',
      inputs: inputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            MyUtil.debug('Cancel clicked');
          }
        },
        {
          text: 'Okay',
          handler: data => {
            MyUtil.debug(['Checkbox data:', data]);
            MyUtil.lodash.forEach(this.pageData.filter.organizations, (item) => {
              if (data.indexOf(item.id) !== -1) {
                item.hidden = false;
              } else {
                item.hidden = true;
              }
            });
            this.applyFilter();
          }
        }
      ]
    });
  }

  private showAttendanceTypeCheckbox() {
    let inputs = MyUtil.lodash.chain(this.pageData.filter.attendance_type).map((item) => {
      return {
        type: 'checkbox',
        label: item.value,
        value: item.key,
        checked: !item.hidden,
      };
    }).value();

    MyUtil.presentAlert({
      'title': 'Choose attendance types to search',
      inputs: inputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            MyUtil.debug('Cancel clicked');
          }
        },
        {
          text: 'Okay',
          handler: data => {
            MyUtil.debug(['Checkbox data:', data]);
            this.pageData.filter.attendance_type.forEach((item) => {
              if (data.indexOf(item.key) !== -1) {
                item.hidden = false;
              } else {
                item.hidden = true;
              }
            })

            this.applyFilter();
          }
        }
      ]
    });
  }

  private showSkillCheckbox(title: string, filter: any) {
    let programIds = MyUtil.getProfileProgramIds();
    let inputs = MyUtil.lodash.chain(MyUtil.cache[MyUtil.DOC_ID.USER_SKILLS]).filter(item => {
      return !item.programs || item.programs.some(el => programIds.indexOf(el) >= 0) || item.programs.length === 0;
    }).orderBy([item => item.name.toLowerCase()], ['asc']).map((item) => {
      let input = {
        type: 'checkbox',
        label: item.name,
        value: item.id,
        checked: (filter && filter.indexOf(item.id) !== -1),
      };
      return input;
    }).value();

    MyUtil.presentAlert({
      title: title,
      inputs: inputs,
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          handler: () => {
            MyUtil.debug('Cancel clicked');
          }
        },
        {
          text: 'Okay',
          handler: data => {
            MyUtil.debug(['Checkbox data:', data]);
            this.pageData.filter.skills = data;
            this.applyFilter();
          }
        }
      ]
    });
  }
}
