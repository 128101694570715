import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NavController, NavParams } from '@ionic/angular';
import { NgxCopilotService } from 'ngx-copilot';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DiagnosticCompleteModal } from 'src/app/components/diagnostic-complete-modal/diagnostic-complete-modal';
import { Appapi } from 'src/app/providers/appapi';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { DiagnosticService } from 'src/app/providers/diagnostic/diagnostic-service';
import { WalkthroughService } from 'src/app/services/walkthrough.service';
import { MyUtil } from 'src/libs/MyUtil';

@Component({
  selector: 'app-rsc-diagnostic-summary',
  templateUrl: './rsc-diagnostic-summary.component.html',
  styleUrls: ['./rsc-diagnostic-summary.component.scss'],
})
export class RscDiagnosticSummaryComponent implements OnInit {

  // Component variables.
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  loading: any;
  questionData: any;
  skillStats: any;
  totalSkillCount = 0;
  skills = [];
  previousSubmissions: any;
  fromPrevious = false;
  hasMessages = false;
  pageLabel = 'DiagnosticSummaryPage';
  routeData: any = {};

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public navCtrl: NavController,
    private copilot: NgxCopilotService,
    public appapi: Appapi,
    public router: Router,
    private route: ActivatedRoute,
    public navParams: NavParams,
    public desktopChecker: DesktopChecker,
    public diagnosticService: DiagnosticService) { }


  ngOnInit(): void {

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData);
    });

    // Skills Stats
    this.questionData = this.routeData.questionData;
    this.fromPrevious = this.routeData.fromPrevious;

    // check if recommendations are active - hide next steps if not
    let userOrg = MyUtil.getRootOrganization();
    this.pageData.showNextSteps = false;

    if (userOrg.enable_recommendations && userOrg.enable_recommendations === 1) {
      this.pageData.showNextSteps = true;
    }

    let skillsToDisplay = [];

    this.questionData.forEach(q => {
      this.skills = this.skills.concat(q.skills);
      if (q.message) {
        this.hasMessages = true;
      }
    });
    var seen = {};
    let uniqueSkills = this.skills.filter(function (item) {
      if (item.is_operational == 0) {
        return seen.hasOwnProperty(item.name) ? false : (seen[item.name] = true);
      }
    }).map(r => r.name);
    let allSkillNames = this.skills.map(r => r.name);
    uniqueSkills.forEach((item) => {
      let skillCount = this.getOccurrence(allSkillNames, item);
      skillsToDisplay.push({ item: [item, skillCount] });
      this.totalSkillCount = (skillCount > this.totalSkillCount) ? skillCount : this.totalSkillCount;
    });
    this.skillStats = skillsToDisplay;

     
    this.startWalkthrough()
  }

  /** 
  * Start walkthrough.
  */
  startWalkthrough() {
    if (!WalkthroughService.isWalkthroughComplete(this.pageLabel) && !WalkthroughService.allWalkthroughsDisabled()) {
      setTimeout(() => {
        this.copilot.checkInit('27');
      }, 1000);
    }
  }

  /** 
  * Re initialize and specify step.
  * @param stepNumber   stepNumber: string.
  */
  initPosition = (stepNumber: any) => this.copilot.checkInit(stepNumber);

  /** 
  * Next step.
  * @param stepNumber   stepNumber: string.
  */
  nextStep = (stepNumber: any) => this.copilot.next(stepNumber);

  /** 
  * Finish copilot walkthroughs.
  */
  done = () => this.copilot.removeWrapper();

  /** 
  * Set Walkthrough state. 
  * @param pageName   Name of page
  * @param value      Boolean - (Has been visited or not)
  */
  setWalkthroughStateHandler(pageName: string, value: boolean) {
    WalkthroughService.setWalkthroughState(pageName, value)
  }

  /** 
  * Percentage of element as pixels.
  */
  percentAsValue(percentage, element) { return (percentage * element) / 100 }

  /** 
  * Pixels of element as Percentage.
  */
  valueAsPercent(value1, value2) {
    return `${(value1 / value2) * 100}%`;
  }

  /** 
  * Get occurrence.
  */
  getOccurrence(array, value) {
    var count = 0;
    array.forEach((v) => (v === value && count++));
    return count;
  }

  /** 
  * Next steps.
  */
  async nextSteps() {
    let reflectionCompleteModal = MyUtil.createModal(DiagnosticCompleteModal, {
      title: "What would you like to do next?",
    });
    (await reflectionCompleteModal).onDidDismiss().then((data) => {
      if (data.data === 'visitRecomendedActivities') {
        this.router.navigate(['/RecommendedComponent']);
      }
      if (data.data === 'visitActivities') {
        this.router.navigate(['/']);
      }
    });
    (await reflectionCompleteModal).present();
  }

  /** 
  * Previous submissions.
  */
  viewPreviousSubmissions() {
    this.router.navigate(['/DiagnosticSummaryListPage', JSON.stringify({ id: this.routeData.id })]);
  }

  /** 
  * View recommended.
  */
  viewRecommended() {
    this.router.navigate(
      ['/RecommendedComponent']);
  }

  /** 
  * View activities.
  */
  viewActivities() {
    this.router.navigate(
      ['/']);
  }

  /** 
  * Retake diagnostic.
  */
  retakeDiagnostic() {
    this.diagnosticService.getDiagnosticTests().then(res => {
      this.pageData.diagnostics = res;
      let array = Object.keys(res)
        .map(function (key) {
          return res[key];
        });
      let selectedTest = array.find(o => o.id === this.routeData.id);
      this.router.navigate(['/DiagnosticQuestionsPage', JSON.stringify({ id: this.routeData.id, test: selectedTest, fromPrevious: false })]);
    });
  }

  /** 
  * Email summary.
  */
  emailSummary() {
    this.diagnosticService.sendSummaryEmail(this.routeData.id).then(res => {
      if (res === 'success') {
        MyUtil.presentToast('Questionnaire summary email sent - please check your Inbox.', { cssClass: 'inkpath-toast' });
      } else {
        console.log("Error on server sending email");
      }
    });
  }

  /** 
  * Open RSC review answers.
  */
  openRscReviewAnswers() {
    this.router.navigate(['/RscReviewAnswers', JSON.stringify(this.routeData)]);
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}