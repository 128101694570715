import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Store, State } from '@ngrx/store';
import * as D3 from 'd3';
import { Appcalc } from 'src/app/providers/appcalc';
import { getDisplayMode } from 'src/app/store/selectors/view.selector';
import { MyUtil } from '../../../libs/MyUtil';
import * as appStore from '../../store';

declare var app_branding: any;

@Directive({
  selector: '[profile-chart]'
})
export class ProfileChart implements OnInit{
  @Input('profile-chart-interval') interval: any;
  @Input('profile-chart-org') orgId: any;
  @Input('profile-chart-program') programId: any;
  canvas: any;
  globalColors: any;
  emptyBarsStroke: any;
  emptyBarsFill: any;
  chartLabelsColor: any;
  displayMode:string;

  constructor(private route: ActivatedRoute,
    private store: Store<appStore.AppViewState>, private state: State<any>,  
    public element: ElementRef, public appcalc: Appcalc, public router: Router, public navParams: NavParams) {
  }

  ngOnInit(): void {

    this.store.select(getDisplayMode).subscribe(mode =>{
        this.displayMode = mode;
    })


    this.globalColors = ['#890385', '#00afbc', '#028826'];
    this.emptyBarsStroke = '#363542';
    this.emptyBarsFill = '#131222';
    this.chartLabelsColor = '#f4f4f4';

    if(typeof app_branding !== 'undefined') {
      if (app_branding && app_branding.profile_chart_colors && app_branding.profile_chart_colors.global) {
        this.globalColors = app_branding.profile_chart_colors.global;
      }

      if (app_branding && app_branding.profile_chart_colors && app_branding.profile_chart_colors.empty_bars && app_branding.profile_chart_colors.empty_bars.stroke) {
        this.emptyBarsStroke = app_branding.profile_chart_colors.empty_bars.stroke;
      }

      if (app_branding && app_branding.profile_chart_colors && app_branding.profile_chart_colors.empty_bars && app_branding.profile_chart_colors.empty_bars.fill) {
        this.emptyBarsFill = app_branding.profile_chart_colors.empty_bars.fill;
      }

      if (app_branding && app_branding.profile_chart_colors && app_branding.profile_chart_colors.labels) {
        this.chartLabelsColor = app_branding.profile_chart_colors.labels;
      }
    }
  }

  ngOnChanges(changes) {
    // render use updated data
    this.appcalc.refreshCompletedActivities().subscribe(() => {

      setTimeout(()=>{
      this.appcalc.refreshPerformancePerSkills(this.interval, this.orgId).subscribe(() => {
        this.render();
        MyUtil.saveToLocalStorage(MyUtil.DOC_ID.USER_PROFILE_CHART_INTERVAL, this.interval);
        MyUtil.saveToLocalStorage(MyUtil.DOC_ID.USER_PROFILE_CHART_ORG_ID, this.orgId);
        MyUtil.saveToLocalStorage(MyUtil.DOC_ID.USER_PROFILE_CHART_PROGRAM_ID, this.programId);
        });
      }, 400);
    });
  }

  render() {
    // prepare data
    let data = this.appcalc.getProfileChartData(this.interval, this.programId);
    console.log('data', data)
    let homeOrg = MyUtil.getRootOrganization();
    let hoursLabel = 'hours';
    if(homeOrg.override_activity_hours === 1) {
      hoursLabel = '';
    }

    // render chart
    let htmlElement = this.element.nativeElement;
    if (data && data.length > 0) {
     // data = MyUtil.lodash.orderBy(data, ['value', 'name'], ['desc', 'asc']);

      data = data.sort(function(a, b) {
        var textA = a.name.toUpperCase();
        var textB = b.name.toUpperCase();
        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    });

      let dataCount = data.length,
        maxData = MyUtil.lodash.maxBy(data, 'value'),
        width = htmlElement.clientWidth,
        barHeight = 72,
        indicatorHeight = 4,
        indicatorWidth = width -36,
        indicatorDy = 36,
        labelDy = 24,
        widthScale = D3.scaleLinear()
          .domain([0, maxData.value * 4 / 3])
          .range([0, indicatorWidth]),
        colorScale = D3.scaleQuantile()
          .domain([0, maxData.value])
          .range(D3.range(3)),
        getColor = (d: any) => {
          let c = colorScale(d.value);
          return this.globalColors[c];
        };

      if (!this.canvas) {
        this.canvas = D3.select(htmlElement)
          .append('svg');
      }
      this.canvas
          .attr('width', width)
          .attr('height', barHeight * dataCount + 60);

      this.canvas.selectAll('g.chartGroup').remove();
      let chartGroup = this.canvas
        .append('g')
        .attr('class', 'chartGroup');

      let emptyBars = chartGroup.selectAll('rect.emptyBar')
        .data(data);

      //enter
      emptyBars.enter()
        .append('rect')
        .attr('height', indicatorHeight)
        .attr('stroke', this.emptyBarsStroke)
        .attr('fill', this.emptyBarsFill)
        .attr('y', (d: any, idx) => {
          return idx * barHeight + indicatorDy;
        })
        .attr('width', indicatorWidth)
        .attr('class', 'emptyBar');

      let dataBars = chartGroup.selectAll('rect.chartBar')
        .data(data);

      //enter
      dataBars.enter()
        .append('rect')
        .attr('height', indicatorHeight)
        .attr('stroke', getColor)
        .attr('fill', getColor)
        .attr('y', (d: any, idx) => {
          return idx * barHeight + indicatorDy;
        })
        .attr('width', 0)
        .attr('class', 'chartBar')
        .transition()
        .duration(1000)
        .attr('width', function(d: any) {
          if(d.value === 0){
            return 0;
          }
          return widthScale(d.value);
        });

      let chartLabels = chartGroup.selectAll('text.chartLabel')
        .data(data);

      // enter
      chartLabels.enter()
        .append('text')
        .attr('fill', this.chartLabelsColor)
        .attr('x', -width)
        .attr('y', (d: any, idx) => {
          return idx * barHeight + labelDy;
        })
        .attr('id', (d: any, idx) => {
          return `skill-${d.id}`;
        })
        .attr('class', 'chartLabel')
        .text((d: any) => {
          return d.name + ' - ' + d.value + ' ' + hoursLabel;
        })
        .transition()
        .duration(1000)
        .attr('x', 0);

      let chartArrow = chartGroup.selectAll('image.right-arrow')
        .data(data);

      // enter
      chartArrow.enter()
        .append('svg:image')
        .attr('x', width-20)
        .attr('y', (d: any, idx) => {
          return idx * barHeight + 10;
        })
        .attr('height', 30)
        .attr('width', 16)
        .attr("xlink:href",
        ()=>{
          if(this.displayMode === "dark-mode"){
            return  "assets/images/icon_right-arrow.svg";
          }else{            
            return  "assets/images/icon_right-arrow-dark.svg";
          }
        }
        )
        .attr("class", "right-arrow");

      let navCtrl = this.router;

      chartGroup.selectAll('rect.clickable')
        .data(data)
        .enter()
          .append('rect')
          .attr('tabindex', 0)
          .attr('height', barHeight)
          .attr('stroke', 'transparent')
          .attr('fill', 'transparent')
          .attr('y', (d: any, idx) => {
            return idx * barHeight;
          })
          .attr('width', width)
          .attr('tappable', '')
          .attr('class', 'clickable')
          .attr('role', 'link')
          .attr('aria-labelledby', (d: any, idx) => {
            return `skill-${d.id}`;
          })
          .on('keydown', (e, item) => {
            if(e.keyCode == 13){
            let pageData = {
              id: item.id,
              color: getColor(item)
            }
            navCtrl.navigate(['/SkillDetailsPage', JSON.stringify(pageData)]);

          }
          })
          .on('click', (data, item) => {
            let pageData = {
              id: item.id,
              color: getColor(item)
            }
            navCtrl.navigate(['/SkillDetailsPage', JSON.stringify(pageData)]);

            // D3.select(this).attr('fill', 'rgba(255,255,255,0.1)')
            // .transition()
            //   .duration(300)
            //   .attr('fill', 'transparent');
          });
    } else {
      if (!this.canvas) {
        this.canvas = D3.select(htmlElement);
      }
      this.canvas.selectAll('g.chartGroup').remove();
    }
  }
}
