import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { NgxCopilotService } from 'ngx-copilot';
import { Observable, Subject } from 'rxjs';
import { GeneralModalComponent } from 'src/app/components/general-modal/general-modal.component';
import { Appapi } from 'src/app/providers/appapi';
import { AuthProvider } from 'src/app/providers/auth/auth';
import { OnboardingService } from 'src/app/providers/onboarding/onboarding-service';
import { SsoLoginProvider } from 'src/app/providers/sso-login/sso-login';
import { UiuxService } from 'src/app/services/uiux.service';
import { WalkthroughService } from 'src/app/services/walkthrough.service';
import { appToOnboarding, getBrandName } from 'src/app/store/selectors/view.selector';
import { MyUtil } from '../../../libs/MyUtil';
import * as appStore from '../../store';

declare var window: any;

@Component({
  selector: 'page-rsc-login',
  templateUrl: 'rsc-login.html',
  styleUrls: ['rsc-login.scss'],

})
export class RscLoginPage implements OnInit, OnDestroy {

  // Component varoables.
  pageLabel = 'RscLoginPage';
  pageData: any = {};
  showPassword: boolean = false;
  routeData: any = {};
  static SSO_LOGIN_EVENT = 'SSO_LOGIN_EVENT';
  appToOnboarding$: Observable<any> = this.store.select(appToOnboarding);
  isMobileView = this.uiux.isMobileView();
  branding = null;
  brandName$: Observable<string> = this.store.select(getBrandName);

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public sanitiser: DomSanitizer,
    private copilot: NgxCopilotService,
    private store: Store<appStore.AppViewState>,
    public onboardingService: OnboardingService,
    public router: Router,
    public navParams: NavParams,
    public uiux: UiuxService,
    public formBuilder: UntypedFormBuilder,
    public appapi: Appapi,
    public auth: AuthProvider,
    public ssoLogin: SsoLoginProvider,
    private titleService:Title) {
      this.titleService.setTitle("Login");
    this.pageData.segment = 'login';
    this.pageData.universities = MyUtil.lodash.values(MyUtil.cache[MyUtil.DOC_ID.APP_UNIVERSITIES]);
    this.pageData.universities = MyUtil.lodash.sortBy(this.pageData.universities, ['name']);

    if (typeof (window.app_branding) == 'undefined') {
      window.app_branding = 'rsc';
    };

    this.pageData.appBranding = (window.app_branding ? window.app_branding : 'rsc');

    // define login form validation
    this.pageData.loginForm = this.formBuilder.group({
      member: ['', Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.pattern("^[0-9]*$"), Validators.required])],
      password: ['', Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.required])],
    });
  }


  ngOnInit(): void {

    this.appapi.syncAppAll().then(async () => {

      this.updateUniversitiesHandler();

      // prepare to handle go to questionnaire
      this.appToOnboarding$.subscribe(response => {
        // check if profile has been completed
        if (!MyUtil.validateProfile()) {
          this.appapi.syncUserAll().then(async () => {
            MyUtil.presentToast('Please complete your profile first', { cssClass: 'inkpath-toast' });
            this.router.navigate(['/UserSettingsPage', JSON.stringify({ "redirect": '/OnboardingPage' })]);
          });
        } else if (response && response.display && response.display === 1) {
          this.router.navigate(['/OnboardingPage', JSON.stringify({ "referer": 'onboarding' })])
        } else {
          // prepare to handle go to questionnaire
          MyUtil.subscribeEvent(MyUtil.EVENT.APP_TO_QUESTIONNAIRE, (res: any) => {
            MyUtil.unsubscribeEvent(MyUtil.EVENT.APP_TO_QUESTIONNAIRE);
            if (res['#status'] === 'success') {
              this.router.navigate(['/ActivitiesPage'])
            } else {
              if (!res['#skipQuestionnaire']) {
                this.router.navigate(['/SkipQuestionnaire'])
              } else {
                this.router.navigate(['/ActivitiesPage'])

              }
            }
          });
        }
      });
    })

    this.startWalkthrough();
  }

  /** 
  * Start walkthrough.
  */
  startWalkthrough() {
    if (!WalkthroughService.isWalkthroughComplete(this.pageLabel) && !WalkthroughService.allWalkthroughsDisabled()) {
      setTimeout(() => {
        this.copilot.checkInit();
      }, 1000);
    }
  }

  /** 
  * Re initialize and specify step.
  * @param stepNumber   stepNumber: string.
  */
  initPosition = (stepNumber: any) => this.copilot.checkInit(stepNumber);

  /** 
  * Next step.
  * @param stepNumber   stepNumber: string.
  */
  nextStep = (stepNumber: any) => this.copilot.next(stepNumber);

  /** 
   * Finish copilot walkthroughs.
   */
  done = () => this.copilot.removeWrapper();

  /** 
   * Set Walkthrough state. 
   * @param pageName   Name of page
   * @param value      Boolean - (Has been visited or not)
   */
  setWalkthroughStateHandler(pageName: string, value: boolean) {
    WalkthroughService.setWalkthroughState(pageName, value)
  }

  async updateUniversitiesHandler() {
    if (this.pageData.segment === 'sso') {
      this.pageData.universities = MyUtil.lodash.values(MyUtil.cache[MyUtil.DOC_ID.APP_UNIVERSITIES]);
      this.pageData.universities = MyUtil.lodash.filter(this.pageData.universities, function (el) {
        return el.sso && el.sso.length > 0;
      });
      this.pageData.universities = MyUtil.lodash.sortBy(this.pageData.universities, ['name']);
    } else {
      this.pageData.universities = await MyUtil.lodash.values(MyUtil.cache[MyUtil.DOC_ID.APP_UNIVERSITIES]);
      // Filter out organizations with single-sign-on - these cannot deal with sign-ups from our system
      // this.pageData.universities = MyUtil.lodash.filter(this.pageData.universities, function(org) {
      //   return !org.sso;
      // });
      this.pageData.universities = await MyUtil.lodash.sortBy(this.pageData.universities, ['name']);
    }
  }

  ionViewDidLoad() {
    MyUtil.firebaseSetScreenName('rsc-login');
  }

  // redirect to SSO page if SSO enabled on organisation
  checkSSO(org) {
    let uni = this.pageData.universities.find(u => u.id === org);
    if (uni.id === org && uni.sso !== null && uni.sso.length > 0) {
      this.pageData.signupForm.controls.oid.value = null;
      this.pageData.segment = 'sso';
      return;
    }
  }

  /** 
  * Toggle password.
  */
  togglePassword(event) {
    event.preventDefault();
    this.showPassword = !this.showPassword;
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  */
  process(action) {
    // validate input and prepare api call
    let data;

    switch (action) {
      case 'rsc-login':
        this.pageData.loginFormSubmitAttempt = true;
        if (!this.pageData.loginForm.valid) {
          MyUtil.presentToast('Please review and revise your input', { cssClass: 'inkpath-toast' });
          return;
        }

        data = this.pageData.loginForm.value;
        // Get the auth token from env.
        data.rsctoken = MyUtil.context.RSC_AUTH_TOKEN;

        break;

      case 'reset-password':
        // Redirect to the RSC reset password page.
        window.location.href = MyUtil.context.RSC_RESET_URL;
        break;
      case 'show-terms-of-use':
        this.appapi.openAppLawBrowser('terms_and_conditions');
        return;
        break;
      case 'show-privacy-policy':
        this.appapi.openAppLawBrowser('privacy_policy');
        return;
        break;
      case 'about': action
        this.router.navigate(['AboutPage']);
        return;
        break;
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }

    let loading = MyUtil.presentLoading();

    if (action == "reset-password") {
      return;
    }

    this.appapi.post('/' + action, data).then(async result => {
      if (result['#status'] === 'success') {
        switch (action) {
          case 'rsc-login':
            // set a session time - limit to 12 hours
            let sessionStart = MyUtil.getUnixTimeStamp();
            MyUtil.saveToLocalStorage('rsc-session', sessionStart + (60 * 60 * 12));
            this.auth.loginRscUser(result['#data'], loading);
            break;
          case 'reset-password':
            MyUtil.firebaseLogEvent('reset-password', { 'member': data.member });
            break;
        }
      } else {
        (await loading).dismiss();

        if (action == "reset-password") {
          // ignore errors if we are resetting password
        } else {
          let modal = MyUtil.createModal(GeneralModalComponent,
            {
              message: this.sanitiser.bypassSecurityTrustHtml(result['#message']),
              buttons: [
                {
                  text: "Close",
                }
              ]
            });
          (await modal).onDidDismiss().then((data: any) => { });
          (await modal).present();
        }
      }
    }).catch(err => {
      MyUtil.error(err);
      MyUtil.presentToast('Oops! Service is not available. Please try again later.', { cssClass: 'inkpath-toast' });
    });
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
