import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MyUtil } from 'src/libs/MyUtil';
import { Appapi } from '../providers/appapi';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit, OnDestroy {

  passwordFormSubmitAttempt = false;
  setPasswordForm: any = undefined;
  errorMessage = '';
  pageTitle = "Set ";
  messageSection = " set.";
  routeData: any = {
    token: '',
    email: '',
  }
  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    public formBuilder: UntypedFormBuilder,
    public router: Router,
    public appapi: Appapi,

  ) {

    
      // define login form validation
      this.setPasswordForm = this.formBuilder.group({
        passwords: this.formBuilder.group({
          newPassword: ['', Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.required])],
          newPassword_confirmation: ['', Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.required])]
        }, {
          validator: (group: UntypedFormGroup) => {
            return (group.get('newPassword').value === group.get('newPassword_confirmation').value ? null : { 'mismatch': true });
          }
        })
      });
   
  }



  ngOnInit() { 
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData);
      if(this.routeData.name === "ResetPasswordComponent" ){
        this.pageTitle = 'Reset ';
        this.messageSection = " reset.";
      }
    });
    
  }

  onSubmit(){
    this.passwordFormSubmitAttempt = true;
    this.errorMessage = '';
    if (!this.setPasswordForm.valid) {
      this.errorMessage = 'Please revise input';
      if (this.setPasswordForm.value.passwords.newPassword !== this.setPasswordForm.value.passwords.newPassword_confirmation) {
        this.errorMessage = "Passwords do not match. ";
      }
     // MyUtil.presentToast(this.errorMessage, { cssClass: 'inkpath-toast' });
      return;
    }else{
      let loading = MyUtil.presentLoading();
      let data = {
        email: this.routeData.params.email,
        token: this.routeData.params.token,
        password: this.setPasswordForm.value.passwords.newPassword,
        password_confirmation: this.setPasswordForm.value.passwords.newPassword_confirmation
      }
      
      this.appapi.post(this.routeData.params.reset_url, data, '', false).then(async result => {
        (await loading).dismiss();
        if (result['#status'] === 'success') {
            this.router.navigate(['/LoginPage']);
            MyUtil.presentToast("Password has been " + this.messageSection, { cssClass: 'inkpath-toast' });
        } else {
          if(result['#message']?.password){
            this.errorMessage += "The password does not meet these requirements: "
            result['#message']?.password.forEach((v,k) => {
              this.errorMessage += v + ' ';
            });
          }
          
          if(result['#message']?.password_confirmation){
            result['#message']?.password_confirmation.forEach((v,k) => {
              this.errorMessage += v + ' ';
            });
          }
          
          if(result['#message']?.token){
            result['#message']?.token.forEach((v,k) => {
              this.errorMessage += v + ' ';
            });
          }
          
          
         // MyUtil.presentToast(MyUtil.responseToMessage(result['#message'], result['#errors']), { cssClass: 'inkpath-toast' });
        }
      }).catch(async err => {
        (await loading).dismiss();
        MyUtil.error(err);
        MyUtil.presentToast('Oops! Service is not available. Please try again later.', { cssClass: 'inkpath-toast' });
      });
    }
  }


 

  // On destroy
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
