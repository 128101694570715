import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { NgxCopilotService } from 'ngx-copilot';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccessibilityModalComponent } from 'src/app/components/accessibility-modal/accessibility-modal.component';
import { Appapi } from 'src/app/providers/appapi';
import { AuthProvider } from 'src/app/providers/auth/auth';
import { OnboardingService } from 'src/app/providers/onboarding/onboarding-service';
import { SsoLoginProvider } from 'src/app/providers/sso-login/sso-login';
import { UiuxService } from 'src/app/services/uiux.service';
import { WalkthroughService } from 'src/app/services/walkthrough.service';
import { appToOnboarding, getBrandName, getDisplayMode, getSSO } from 'src/app/store/selectors/view.selector';
import { MyUtil } from '../../../libs/MyUtil';
import * as appStore from '../../store';
import { Title } from '@angular/platform-browser';
import { RedirectProvider } from 'src/app/providers/redirect/redirect';

declare var window: any;

/**
 * NOTE (IPD-1695)
 * Page segments have been changed to allow selecting of index as per the new Angular Material tabs
 * 0 = Login
 * 1 = SSO
 * 2 = Sign Up
 */

@Component({
  selector: 'page-login',
  templateUrl: 'login.html',
  styleUrls: ['login.scss'],

})
export class LoginPage implements OnInit, OnDestroy {

  // Component variables.
  appToOnboarding$: Observable<any> = this.store.select(appToOnboarding);
  branding = null;
  pageData: any = {};
  showPassword: boolean = false;
  routeData: any = {};
  static SSO_LOGIN_EVENT = 'SSO_LOGIN_EVENT';
  displayMode$: Observable<string> = this.store.select(getDisplayMode);
  brandName$: Observable<string> = this.store.select(getBrandName);
  pageLabel = 'LoginPage';
  sso$: Observable<string> = this.store.select(getSSO);
  isMobileView = this.uiux.isMobileView();

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();
  
  constructor(
    private copilot: NgxCopilotService,
    private store: Store<appStore.AppViewState>,
    public onboardingService: OnboardingService,
    public router: Router,
    public navParams: NavParams,
    public redirectProvider: RedirectProvider,
    public uiux: UiuxService,
    public formBuilder: UntypedFormBuilder,
    public appapi: Appapi,
    public auth: AuthProvider,
    public ssoLogin: SsoLoginProvider,
    private titleService:Title) {
      this.titleService.setTitle("Login");

    this.pageData.universities = MyUtil.lodash.values(MyUtil.cache[MyUtil.DOC_ID.APP_UNIVERSITIES]);
    this.pageData.universities = MyUtil.lodash.sortBy(this.pageData.universities, ['name']);

    // define login form validation
    this.pageData.loginForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.pattern(MyUtil.CONST.REGREX_EMAIL), Validators.required])],
      password: ['', Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.required])],
    });

    // define sign up form validation
    this.pageData.signupForm = this.formBuilder.group({
      oid: [(window.app_branding ? window.app_branding.org_id : null), Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.maxLength(MyUtil.CONST.INPUT_MAX_LENGTH), Validators.pattern(MyUtil.CONST.REGREX_EMAIL), Validators.required])],
      accept: [false, Validators.compose([(control: UntypedFormControl): any => {
        if (control.value) {
          return null;
        }
        return { 'not accepted': true };
      }, Validators.required])],
      accept2: [false, Validators.compose([(control: UntypedFormControl): any => {
        if (control.value) {
          return null;
        }
        return { 'not accepted': true };
      }, Validators.required])]
    });

    this.pageData.ssoForm = this.formBuilder.group({
      oid: [(window.app_branding ? window.app_branding.org_id : null), Validators.required],
      accept: [false, Validators.compose([(control: UntypedFormControl): any => {
        if (control.value) {
          return null;
        }
        return { 'not accepted': true };
      }, Validators.required])],
      accept2: [false, Validators.compose([(control: UntypedFormControl): any => {
        if (control.value) {
          return null;
        }
        return { 'not accepted': true };
      }, Validators.required])],
      accept3: [false, Validators.compose([Validators.required])],
    });

    this.brandName$.pipe(takeUntil(this.destroy$)).subscribe(brandName => {
      this.branding = brandName;
      if (brandName === 'imperial' || brandName === 'rmit') {
        this.pageData.segment = 1;
      } else {
        this.pageData.segment = 0;
      }
    });

  }


  ngOnInit(): void {
    this.redirectProvider.processRedirectTarget(this.router, null);
    this.appapi.syncAppAll().then(async () => {

      await this.updateUniversitiesHandler();
      
      if (this.appapi.isLoggedIn()) {
        // prepare to handle go to questionnaire
        this.appToOnboarding$.subscribe(response => {
          // check if profile has been completed
          if (!MyUtil.validateProfile()) {
            this.appapi.syncUserAll().then(async () => {
              MyUtil.presentToast('Please complete your profile first', { cssClass: 'inkpath-toast' });
              this.router.navigate(['/UserSettingsPage', JSON.stringify({ "redirect": '/OnboardingPage' })]);
            });
          } else if (response && response.display && response.display === 1) {
            this.router.navigate(['/OnboardingPage', '{}']);
            return;
          } else {
            // prepare to handle go to questionnaire
            MyUtil.subscribeEvent(MyUtil.EVENT.APP_TO_QUESTIONNAIRE, (res: any) => {
              MyUtil.unsubscribeEvent(MyUtil.EVENT.APP_TO_QUESTIONNAIRE);
              if (res['#status'] === 'success') {
                this.router.navigate(['/ActivitiesPage'])
              } else {
                if (!res['#skipQuestionnaire']) {
                  this.router.navigate(['/SkipQuestionnaire'])
                } else {
                  this.router.navigate(['/ActivitiesPage'])
                }
              }
            });
          }
        });
      }
    })
  }

  /** 
  * Start walkthrough.
  */
  startWalkthrough() {
    if (!WalkthroughService.isWalkthroughComplete(this.pageLabel) && !WalkthroughService.allWalkthroughsDisabled()) {
      setTimeout(() => {
        this.copilot.checkInit();
      }, 1000);
    }
  }

  /** 
  * Re initialize and specify step.
  * @param stepNumber   stepNumber: string.
  */
  /** 
  * Re initialize and specify step.
  * @param stepNumber   stepNumber: string.
  */
  initPosition = (stepNumber: any) => this.copilot.checkInit(stepNumber);

  /** 
  * Next step.
  * @param stepNumber   stepNumber: string.
  */
  /** 
  * Next step.
  * @param stepNumber   stepNumber: string.
  */
  nextStep = (stepNumber: any) => this.copilot.next(stepNumber);

  /** 
   * Finish copilot walkthroughs.
   */
  done = () => this.copilot.removeWrapper();


  /** 
   * Set Walkthrough state. 
   * @param pageName   Name of page
   * @param value      Boolean - (Has been visited or not)
   */
  setWalkthroughStateHandler(pageName: string, value: boolean) {
    WalkthroughService.setWalkthroughState(pageName, value)
  }
  segmentChanged(ev: any) {
    this.pageData.segment = ev.index;
    this.updateUniversitiesHandler();
  }

  async updateUniversitiesHandler() {
    if (this.pageData.segment === 1) {
      this.pageData.universities = MyUtil.lodash.values(MyUtil.cache[MyUtil.DOC_ID.APP_UNIVERSITIES]);
      this.pageData.universities = MyUtil.lodash.filter(this.pageData.universities, function (el) {
        return el.sso && el.sso.length > 0;
      });
      this.pageData.universities = MyUtil.lodash.sortBy(this.pageData.universities, ['name']);
    } else {
      this.pageData.universities = await MyUtil.lodash.values(MyUtil.cache[MyUtil.DOC_ID.APP_UNIVERSITIES]);
      // Filter out organizations with single-sign-on - these cannot deal with sign-ups from our system
      // this.pageData.universities = MyUtil.lodash.filter(this.pageData.universities, function(org) {
      //   return !org.sso;
      // });
      this.pageData.universities = await MyUtil.lodash.sortBy(this.pageData.universities, ['name']);
    }

    // remove any orgs which are set to be hidden from signup
    this.pageData.universities = MyUtil.lodash.filter(this.pageData.universities, function (el) {
      return el.hide_from_login == 0;
    });

    return this.pageData.universities;
  }


  ionViewDidLoad() {
    MyUtil.firebaseSetScreenName('login');
  }

  // redirect to SSO page if SSO enabled on organisation
  checkSSO(org) {
    let uni = this.pageData.universities.find(u => u.id === org);
    if (uni.id === org && uni.sso !== null && uni.sso.length > 0) {
      this.pageData.signupForm.controls.oid.value = null;
      this.pageData.ssoForm.controls.oid.value = uni.id;
      // having to manually set the oid field to valid due to form changes
      this.pageData.ssoForm.controls['oid'].markAsTouched();
      this.pageData.ssoForm.controls['oid'].setErrors(null);
      this.pageData.segment = 1;
      this.updateUniversitiesHandler();
      return;
    }
  }

  /** 
  * Toggle password.
  */
  togglePassword(event) {
    event.preventDefault();
    this.showPassword = !this.showPassword;
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  */
  process(action) {
    // validate input and prepare api call
    let data;

    // check whether the user needs to update the app first
    let upgradeRequired = MyUtil.appRequiresUpgrade();

    if(upgradeRequired !== null) {
      console.log("APP REQUIRES UPGRADE");
      // log the user out
      if (this.appapi.isLoggedIn()) {
        this.appapi.forgetAppUser(true);
      }

      // show alert
      MyUtil.presentAlert({
        title: 'App Requires Update',
        message: upgradeRequired,
        buttons: [
          {
            text: 'OK',
          }
        ]
      });

      //this.router.navigate(['/LoginPage']);

    } else {

      switch (action) {
        case 'login':
          this.pageData.loginFormSubmitAttempt = true;
          if (!this.pageData.loginForm.valid) {
            MyUtil.presentToast('Please review and revise your input', { cssClass: 'inkpath-toast' });
            return;
          }
          data = this.pageData.loginForm.value;
          if(this.branding) {
            data.branded = this.branding != '' && this.branding != 'inkpath';
          }
          break;

        case 'sign-up':
          this.pageData.signupFormSubmitAttempt = true;
          if (!this.pageData.signupForm.valid) {

            MyUtil.presentToast('Please review and revise your input', { cssClass: 'inkpath-toast' });
            return;
          }
          data = this.pageData.signupForm.value;

          break;
        case 'reset-password':
          if (!this.pageData.loginForm.controls.email.valid) {
            MyUtil.presentToast('Please enter your sign up email', { cssClass: 'inkpath-toast' });
            return;
          }
          data = this.pageData.loginForm.value;
          break;
        case 'show-terms-of-use':
          this.appapi.openAppLawBrowser('terms_and_conditions');
          return;
          break;
        case 'show-privacy-policy':
          this.appapi.openAppLawBrowser('privacy_policy');
          return;
          break;
        case 'about': action
          this.router.navigate(['AboutPage']);
          return;
          break;
        case 'sso-login':
          if (!this.pageData.ssoForm.valid) {
            MyUtil.presentToast('Please review and revise your input', { duration: 2000, cssClass: 'inkpath-toast' });
            return;
          }
          data = this.pageData.ssoForm.value;
          const organization = MyUtil.cache[MyUtil.DOC_ID.APP_UNIVERSITIES][data.oid];
          this.ssoLogin.initializeSsoLogin(organization);
          return;
        case 'show-terms-of-use':
          this.appapi.openAppLawBrowser('terms_and_conditions');
          return;
        case 'show-privacy-policy':
          this.appapi.openAppLawBrowser('privacy_policy');
          return;
        case 'open-accessibility':
          this.accessibilityHandler();
          break;
        default:
          MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
          return;
      }

      this.appapi.post('/' + action, data).then(async result => {

        if (result['#status'] === 'success') {
          switch (action) {
            case 'login':
              this.auth.loginUser(result['#data']);
              break;
            case 'sign-up':
              MyUtil.firebaseLogEvent('sign-up_success', { 'email': data.email });

              this.pageData.segment = 0;
              this.pageData.loginForm.controls.email.setErrors(null);
              this.pageData.loginForm.controls.email.setValue(data.email);

              //Set the current localOid to the one just signed up to
              if(result['#data'] && result['#data']['localOid']) {
                MyUtil.saveToLocalStorage('localOid', result['#data']['localOid']);
              }

              MyUtil.presentAlert({
                message: result['#message'],
                cssClass: 'alertSignup',
                buttons: [
                  {
                    text: 'Close',
                    handler: () => {
                      return;
                    }
                  }
                ],
              });
              break;
            case 'reset-password':
              MyUtil.firebaseLogEvent('reset-password', { 'email': data.email });
              MyUtil.presentToast(result['#message'], { cssClass: 'inkpath-toast' });
              break;
          }
        } else {
          MyUtil.presentAlert({
            title: 'Error',
            message: MyUtil.responseToMessage(result['#message'], result['#errors']),
            buttons: [
              {
                text: 'OK',
              }
            ]
          });
        }
      }).catch(err => {
        MyUtil.error(err);
        MyUtil.presentToast('Oops! Service is not available. Please try again later.', { cssClass: 'inkpath-toast' });
      });
    }
  }

  async accessibilityHandler() {
    let accessibilityModal = MyUtil.createModal(AccessibilityModalComponent, {});
    (await accessibilityModal).onDidDismiss().then((data: any) => { });
    (await accessibilityModal).present();
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
