import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { RedirectProvider } from 'src/app/providers/redirect/redirect';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

// @IonicPage({
//   name: 'redirect',
//   segment: 'redirect/:target',
// })
@Component({
  selector: 'page-redirect',
  templateUrl: 'redirect.html',
})
export class RedirectPage implements OnDestroy {

  routeData: string = "";

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public router: Router, 
    public navParams: NavParams, 
    public redirectProvider: RedirectProvider,
    private route: ActivatedRoute,
  ) { }


  ngOnInit() {

    // process redirect request
    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = params.pageData;
      console.log('params.pageDat', params.pageData)
    });

    this.redirectProvider.setRedirectTarget(this.routeData);

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
