import { Component } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";

@Component({
  selector: "switch-org-modal",
  templateUrl: "switch-org-modal.html",
})
export class SwitchOrgModal {

  // Component variables.
  public data: any;
  public alternativeOid: any = 0;
  public hasSingleSignOn = false;
  public alternativeProfilesData: Array<any> = [];

  constructor(public params: NavParams, public viewCtrl: ModalController) {
    if (params) {
      this.data = params.data;
      this.data.alternativeProfilesData.forEach(item => {
        if(item.sso) {
          this.hasSingleSignOn = true;
        } 
      });
    }
  }

  // Handle action.
  handleAction() {
    this.viewCtrl.dismiss(this.alternativeOid);
  }

  // Close modal.
  closeModal() {
    this.viewCtrl.dismiss();
  }
}
