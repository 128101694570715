import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NavParams } from '@ionic/angular';
import { Appapi } from 'src/app/providers/appapi';
import { Appcalc } from 'src/app/providers/appcalc';
import { CoursesService } from 'src/app/providers/courses-service';
import { DesktopChecker } from 'src/app/providers/desktopChecker';
import { MyUtil } from '../../../libs/MyUtil';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'page-skill-details',
  templateUrl: 'skill-details.html',
  styleUrls: ['skill-details.scss']
})
export class SkillDetailsPage implements OnInit {

  // Component variables.
  @ViewChild('Content', { static: true }) content;
  pageData: any = {};
  routeData: any;

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    public coursesService: CoursesService,
    public router: Router,
    public navParams: NavParams,
    public appapi: Appapi,
    public courseService: CoursesService,
    public appcalc: Appcalc,
    public desktopChecker: DesktopChecker,
    private titleService:Title) {
      this.titleService.setTitle("Skill Details"); }

  ngOnInit(): void {

    this.route.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
      this.routeData = JSON.parse(params.pageData);
    });

    // expand help for first time landing on the page
    if (MyUtil.context.helpStatus[MyUtil.HELP_ID.SKILL_DETAILS]) {
      this.pageData.helperToggle = false;
    } else {
      this.pageData.helperToggle = true;
      this.appapi.setAppHelpStatus(MyUtil.HELP_ID.SKILL_DETAILS, true);
    }

    let id = this.routeData.id;
    this.pageData.progressColor = this.routeData.color;
    this.pageData.skill = MyUtil.getSkill(id);
    this.pageData.skillDisplay = {};
    this.pageData.skillSegments = 'progress';

    MyUtil.firebaseSetScreenName('skill-details');

    // render use updated data
    this.appcalc.refreshCompletedActivities().subscribe(() => {
      // add the missing course activities
      this.renderPage();
    });

    MyUtil.firebaseLogEvent('view_did_enter', { name: 'skill-details', data: this.navParams.data });
  }

  ionViewDidLeave() {
    MyUtil.firebaseLogEvent('view_did_leave', { name: 'skill-details', data: this.navParams.data });
  }

  /** 
  * Actions.
  * @param action   Case for action in switch statement.
  * @param item     Parameter to process in action.
  */
  process(action: string, item?: any) {
    switch (action) {
      case 'filter-activity-list-to-skill':
        this.filterActivitiesBySkill();
        return;
      case 'activity-detail':
        this.router.navigate(['/ActivityDetailPage', JSON.stringify({ id: item.id, activityObject: item })]);
        return;
      case 'course-detail':
        this.coursesService.checkUserIsBooked(item.id).then(res => {
          this.coursesService.getCourseDetails(item.id).then(details => {
            if (res && res.course_id == item.id) {
              this.router.navigate(['/CourseDetailPage', JSON.stringify({ courseID: item.id, isBooked: res.is_booked, onWaitingList: res.on_waiting_list })]);
            } else {
              this.router.navigate(['/CourseDetailPage', JSON.stringify({ courseID: item.id, isBooked: false, onWaitingList: false })]);
            }
          })
        });
        return;
      default:
        MyUtil.presentToast('"' + action + '" is not handled', { cssClass: 'inkpath-toast' });
        return;
    }
  }

  /** 
  * Render page.
  */
  renderPage() {
    let data = this.pageData.skill;
    let id = this.pageData.skill.id;
    let homeOrg = MyUtil.getRootOrganization();
    this.appcalc.refreshPerformancePerSkills(0).subscribe((performance) => {
      let skill_progress = MyUtil.lodash.filter(performance, (item) => {
        return parseInt(item.id) == id;
      });

      if (skill_progress.length > 0) {
        skill_progress = skill_progress[0];
      } else {
        skill_progress = {
          value: 0,
          activities: [],
          courses: [],
        }
      }

      this.pageData.homeOrg = homeOrg;

      this.pageData.skillDisplay = {
        name: data.name,
        code: data.code,
        description: data.description,
        hours_logged: (skill_progress && skill_progress.value) ? skill_progress.value : 0,
        activities: MyUtil.lodash.chain(skill_progress.activities).filter((act) => MyUtil.getActivity(act.activity_id)).map((item) => {

          let activity = MyUtil.getActivity(item.activity_id);
          let startDisplay = MyUtil.formatUnixTimeStampDate(activity.start_at);
          let endDisplay = MyUtil.formatUnixTimeStampDate(activity.end_at);

          return {
            id: activity.id,
            name: activity.name,
            provider: activity.provider,
            period: (startDisplay === endDisplay ? startDisplay : 'from ' + startDisplay + ' to ' + endDisplay),
            venue: activity.venue,
            time_logged: item.time_logged,
            activity_template_id: activity.activity_template_id
          };
        }).value(),

        courses: MyUtil.lodash.chain(skill_progress.courses).map((item) => {

          let startDisplay = MyUtil.formatUnixTimeStampDate(item.start_at);
          let endDisplay = MyUtil.formatUnixTimeStampDate(item.end_at);

          return {
            id: item.course_id,
            name: item.name,
            period: (startDisplay === endDisplay ? startDisplay : 'from ' + startDisplay + ' to ' + endDisplay),
            time_logged: item.time_logged
          };
        }).value(),
      }
    });
  }

  /** 
  * Filter activities by skill.
  */
  private filterActivitiesBySkill() {
    this.router.navigate(['/ActivityListPage', JSON.stringify({
      skills: [this.pageData.skill.id],
    })]);
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
