import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DiagnosticService } from 'src/app/providers/diagnostic/diagnostic-service';
import { getBrandName } from 'src/app/store/selectors/view.selector';
import { MyUtil } from 'src/libs/MyUtil';
import { Appapi } from "../../providers/appapi";
import { DesktopChecker } from "../../providers/desktopChecker";
import * as appStore from '../../store';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'page-diagnostic-list',
  templateUrl: 'diagnostic-list.html',
  styleUrls: ['diagnostic-list.scss'],
})
export class DiagnosticListPage implements OnInit, OnDestroy {

  // Component variables.
  @ViewChild('Content', { static: true }) content;
  branding = null;
  brandName$: Observable<string> = this.store.select(getBrandName);  
  pageData: any = {};
  loading: any;

  /**
  * Used in takeUntil to unsubscribe subscriptions on destroy.
  */
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public router: Router,
    public navCtrl: NavController,
    public appapi: Appapi,
    private store: Store<appStore.AppViewState>,
    public desktopChecker: DesktopChecker,
    public diagnosticService: DiagnosticService,
    private titleService:Title) {
      this.titleService.setTitle("Questionnaires"); 
  }

  ngOnInit(): void {

    this.brandName$.pipe(takeUntil(this.destroy$)).subscribe(brandName => {
      this.branding = brandName;
    })

     

    // get any available diagnostic tests
    let diagnostics = null;
    this.diagnosticService.getDiagnosticTests().then(res => {
      diagnostics = Object.keys(res).map(index => {
        let diagnostic = res[index];
        return diagnostic;
      });
      this.pageData.diagnostics = diagnostics;
      this.store.dispatch(new appStore.DiagnosticsSet(diagnostics));
    });

  }

  /** 
  * Open diagnostic.
  * @param id
  */
  openDiagnostic(id) {
    let selectedTest = this.pageData.diagnostics.find(o => o.id === id);
    if (selectedTest.complete) {
      // get the submission data
      let questionData = this.diagnosticService.getDiagnosticTestSubmission(id).then(result => {
        if (this.branding === 'rsc') {
          this.router.navigate(['/RscDiagnosticSummaryComponent', JSON.stringify({ id: id, selectedTest: selectedTest, questionData: result })]);
        } else {
          this.router.navigate(['/DiagnosticSummaryPage', JSON.stringify({ id: id, questionData: result })]);
        }
      });
    } else {
      this.router.navigate(['/DiagnosticQuestionsPage', JSON.stringify({ id: id, test: selectedTest, resumed: selectedTest.started, startIndex: selectedTest.start_index })]);
    }
  }

  // On destroy.
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
