import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Appapi } from "./appapi";
import { MyUtil } from "src/libs/MyUtil";

@Injectable({
  providedIn: 'root',
})
export class CoursesService {
  constructor(public appapi: Appapi, public httpClient: HttpClient) {}


  public getAvailableCourses():Promise<any> {
    let data = [];
    return this.appapi.get("/sync/courses",{})
      .then((result) => {

        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }

  public getAvailableAffiliateCourses():Promise<any> {
    let data = [];
    return this.appapi.get("/sync/funding-courses",{})
      .then((result) => {

        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }

  public getActivitiesInCourse(courseID: number, isBooked: boolean) : Promise<any> {
    let data = [];
    return this.appapi.post("/sync/course/"+courseID+"/activities/list", { is_booked: isBooked })
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }

  public listCourseBookingClashes(courseId: number, isBooked: boolean): Promise<any> {
    let data = [];
    return this.appapi
      .post("/list/" + courseId + "/course-booking-clashes", { is_booked: isBooked })
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }

  public bookPlaceOnCourse(courseId: number, postObj: any):Promise<any> {
    let data;
    return this.appapi.post("/course/" + courseId + "/book", postObj, postObj)
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }


  public convertHybridCourseBooking(courseId: number, postObj: any):Promise<any> {
    let data;
    return this.appapi.post("/course/" + courseId + "/convert", postObj)
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }


  public getUsersCourses():Promise<any>{
    let data = [];
    return this.appapi.get("/sync/user/courses",{})
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }


  public getAllUserCourseActivities(): Promise<any> {
    let data = [];
    return this.appapi.get("/sync/all-course/activities",{})
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }


  public getCourseDetails(id: number):Promise<any>{
    let data = [];
    return this.appapi.get("/sync/course/"+id,{})
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }


  public listBookingStatus(): Promise<any> {
    let data = [];
    return this.appapi
      .post("/list/courses-booked", {})
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }


  public checkUserIsBooked(id: number): Promise<any>{
    let data = [];
    return this.appapi.get("/list/" + id + "/course-booked", {})
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });
  }

  public cancelBooking(courseId: number, labelKey: number):Promise<any>{
    let data = [];
    return this.appapi.post("/course/" + courseId + "/cancel-booking", {cancel_reason: labelKey})
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });


  }


  public getActivityStatusesByCourseId(id:number):Promise<any>{
    let data = [];
    return this.appapi.get("/course/load/booking/"+id,{})
      .then((result) => {
        if (result["#status"] === "success") {
          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });




  }

  public getCoursesQuestions(id:number):Promise<any>{
    let data = [];
    return this.appapi.get("/course/"+id+"/booking-metadata",{})
      .then((result) => {
        if (result["#status"] === "success") {

          if(result['#strikeBanText'] && result['#strikeBanText'] != null) {
            return result['#strikeBanText'];
          }

          data = result["#data"];
        } else {
          MyUtil.error(
            MyUtil.responseToMessage(result["#message"], result["#errors"])
          );
        }
        return data;
      })
      .catch((err) => {
        MyUtil.error(err);
        return data;
      });




  }



}
