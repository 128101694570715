import { Action } from '@ngrx/store';
import { Activity } from '../reducers/view.reducer';
import { Diagnostic } from 'src/app/valueObjects/diagnostics.vo';

// Load Activity.
export const LOAD_ACTIVITY = '[Activity] LOAD_ACTIVITY';
export const LOAD_ACTIVITY_FAIL = '[Activity] LOAD_ACTIVITY_FAIL';
export const LOAD_ACTIVITY_SUCCESS = '[Activity] LOAD_ACTIVITY_SUCCESS';
export const ACTIVITY_SELECTED_ID_SET = '[Activity] ACTIVITY_SELECTED_ID_SET';
// Accessibility.
export const DISPLAY_THEME_SET = '[Accessibility] DISPLAY_THEME_SET';
export const DISPLAY_MODE_SET = '[Accessibility] DISPLAY_MODE_SET';
export const FONT_SIZE_SET = '[Accessibility] FONT_SIZE_SET';
export const WALKTHROUGH_LABEL_SET = '[Walkthrough] WALKTHROUGH_LABEL_SET';
// Branding
export const BRANDING_SET = '[Branding] BRANDING_SET';
//SSO
export const SSO_SET = '[sso] SSO_SET';
// Onboarding.
export const APP_TO_ONBOARDING_SET = '[event] APP_TO_ONBOARDING_SET';
// Menu
export const MENU_STATE_SET = '[Menu] MENU_STATE_SET';
// Find Activities State
export const FIND_ACTIVITIES_FILTER_STATE_SET = '[Find Activities] FIND_ACTIVITIES_FILTER_STATE_SET';
// Username
export const USERNAME_SET = '[username] USERNAME_SET';
// diagnostics
export const DIAGNOSTICS_SET = '[diagnostics] DIAGNOSTICS_SET';

export class LoadActivity implements Action {
  readonly type = LOAD_ACTIVITY;
}

export class FindActivitiesFilterStateSet implements Action {
  readonly type = FIND_ACTIVITIES_FILTER_STATE_SET;
  constructor(public payload: any) { }
}

export class ActivitySelectedIdSet implements Action {
  readonly type = ACTIVITY_SELECTED_ID_SET;
  constructor(public payload: any) { }
}

export class DiagnosticsSet implements Action {
  readonly type = DIAGNOSTICS_SET;
  constructor(public payload: Diagnostic[]) { }
}

export class LoadActivityFail implements Action {
  readonly type = LOAD_ACTIVITY_FAIL;
  constructor(public payload: any) { }
}

export class LoadActivitySuccess implements Action {
  readonly type = LOAD_ACTIVITY_SUCCESS;
  constructor(public payload: Activity[]) { }
}

// Accessibility
export class DisplayModeSet implements Action {
  readonly type = DISPLAY_MODE_SET;
  constructor(public payload: string) { }
}
export class DisplayThemeSet implements Action {
  readonly type = DISPLAY_THEME_SET;
  constructor(public payload: string) { }
}

export class FontSizeSet implements Action {
  readonly type = FONT_SIZE_SET;
  constructor(public payload: string) { }
}
export class usernameSet implements Action {
  readonly type = USERNAME_SET;
  constructor(public payload: string) { }
}

// Branding
export class BrandingSet implements Action {
  readonly type = BRANDING_SET;
  constructor(public payload: any) { }
}

// Walkthrough
export class WalkthroughLabelSet implements Action {
  readonly type = WALKTHROUGH_LABEL_SET;
  constructor(public payload: string) { }
}

// SSO
export class SSOSet implements Action {
  readonly type = SSO_SET;
  constructor(public payload: any) { }
}

// Onboarding
export class AppToOnboardingSet implements Action {
  readonly type = APP_TO_ONBOARDING_SET;
  constructor(public payload: any) { }
}
// Mobile Menu
export class MenuStateSet implements Action {
  readonly type = MENU_STATE_SET;
  constructor(public payload: string) { }
}


// Action Types
export type ViewAction
  = LoadActivity |
  LoadActivityFail |
  MenuStateSet |
  DisplayModeSet |
  DisplayThemeSet |
  FontSizeSet |
  BrandingSet |
  WalkthroughLabelSet |
  ActivitySelectedIdSet |
  LoadActivitySuccess |
  AppToOnboardingSet |
  usernameSet |
  DiagnosticsSet |
  FindActivitiesFilterStateSet |
  SSOSet;
