import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AppViewState } from "../reducers";
import { ViewState } from "../reducers/view.reducer";

export const getActivityLoading = (state: ViewState) => state.loading;
export const getActivityLoaded = (state: ViewState) => state.loaded;

export const getActivity = (state: ViewState) =>{
  if(!state){
    return {};
  }
  return state.entities;
}

// View State.
export const getViewState = createFeatureSelector<AppViewState>('app');

// Report State - sub data.
export const getAppState = createSelector(getViewState, (state: AppViewState) => state.view);

// Selectors for different properties.
export const getAllActivityEntities = createSelector(getAppState, getActivity);
export const getAllActivityLoaded = createSelector(getAppState, getActivityLoaded);
export const getAllActivityLoading = createSelector(getAppState, getActivityLoading);
export const getSelectedActivityId = createSelector(getAppState, (state: ViewState) => state.selectedActivityId);
export const getDisplayMode = createSelector(getAppState, (state: ViewState) => state.displayMode);
export const getDisplayTheme = createSelector(getAppState, (state: ViewState) => state.displayTheme);
export const getFontSize = createSelector(getAppState, (state: ViewState) => state.fontSize);
export const getWalkthroughLabel = createSelector(getAppState, (state: ViewState) => state.walkthroughLabel);
export const getBrandName = createSelector(getAppState, (state: ViewState) => state.branding);
export const getUsername = createSelector(getAppState, (state: ViewState) => state.username);
export const getActivityListFilterState = createSelector(getAppState, (state: ViewState) => state.findActivitiesFilters);
export const getSSO = createSelector(getAppState, (state: ViewState) => state.sso);
export const appToOnboarding = createSelector(getAppState, (state: ViewState) => state.appToOnboarding);
export const getMenuState = createSelector(getAppState, (state: ViewState) => state.menuStateSet);
export const getDiagnosticsState = createSelector(getAppState, (state: ViewState) => state.diagnostics);
export const getAllActivity = createSelector(getAllActivityEntities, (entities) => {
  // Convert object to array.
  return Object.keys(entities).map(id => entities[parseInt(id, 10)]);
});
