import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivityListItemVO } from 'src/app/valueObjects/lists.vo';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {

  // Inputs.
  @Input() label?: string;
  @Input() name?: string;
  @Input() type?: 'button';
  @Input() icon?: string;
  @Input() disabled?: boolean;
  @Input() className?= '';
  @Input() iconPosition?: string;
  @Input() ariaPressed?: string;
  @Input() ariaLabel?: string;
  @Input() id?: string;
  @Input() loading?= false;
  @Input() ariaExpanded?: boolean;
  @Input() ariaChecked?: boolean;
  @Input() role?;

  /**
  * Clicked event emitter.
  */
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit() { }

  /** 
  * Click handler.
  * @param item   Emits item data object that has been clicked.
  */
  clickHandler($event) {
    this.clicked.emit($event);
  }
}
