import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { UntypedFormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner/ngx';
import { Calendar } from '@awesome-cordova-plugins/calendar/ngx';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { Device } from '@awesome-cordova-plugins/device/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { FileTransfer } from '@awesome-cordova-plugins/file-transfer/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { IonicModule, IonicRouteStrategy, NavParams } from '@ionic/angular';
//import { IonicStorageModule } from '@ionic/storage-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxCopilotModule } from 'ngx-copilot';
import { AboutPage } from 'src/app/pages/about/about';
import { ActivityBookingPage } from 'src/app/pages/activity-booking/activity-booking';
import { ActivityCompletePage } from 'src/app/pages/activity-complete/activity-complete';
import { ActivityDetailPage } from 'src/app/pages/activity-detail/activity-detail';
import { ActivityEditPage } from 'src/app/pages/activity-edit/activity-edit';
import { ActivityListPage } from 'src/app/pages/activity-list/activity-list';
import { CareerPathSkillTileDetailsPage } from 'src/app/pages/career-path-skill-tile-details/career-path-skill-tile-details';
import { CareerPathPage } from 'src/app/pages/career-path/career-path';
import { ChooseAPathwayPage } from 'src/app/pages/choose-a-pathway/choose-a-pathway';
import { CourseDetailPage } from 'src/app/pages/course-detail/course-detail';
import { CoursesListPage } from 'src/app/pages/courses-list/courses-list';
import { EmailSettingsPage } from 'src/app/pages/email-settings/email-settings';
import { FundingListPage } from 'src/app/pages/funding-list/funding-list';
import { GoalDetailPage } from 'src/app/pages/goal-detail/goal-detail';
import { GoalEditPage } from 'src/app/pages/goal-edit/goal-edit';
import { GoalListPage } from 'src/app/pages/goal-list/goal-list';
import { LoginPage } from 'src/app/pages/login/login';
import { ManagedFilePage } from 'src/app/pages/managed-file/managed-file';
import { PasswordPage } from 'src/app/pages/password/password';
import { PathwayManualCareerTypePage } from 'src/app/pages/pathway-manual-career-type/pathway-manual-career-type';
import { PathwayManualPage } from 'src/app/pages/pathway-manual/pathway-manual';
import { PathwaySuggestedPage } from 'src/app/pages/pathway-suggested/pathway-suggested';
import { QuestionnaireIntroPage } from 'src/app/pages/questionnaire-intro/questionnaire-intro';
import { QuestionnairePage } from 'src/app/pages/questionnaire/questionnaire';
import { RscLoginPage } from 'src/app/pages/rsc-login/rsc-login';
import { SkillDetailsPage } from 'src/app/pages/skill-details/skill-details';
import { SkillsAnalysisSummaryPage } from 'src/app/pages/skills-analysis-summary/skills-analysis-summary';
import { SkipQuestionnaire } from 'src/app/pages/skip-questionnaire/skip-questionnaire';
import { UserSettingsPage } from 'src/app/pages/user-settings/user-settings';
import { UserPage } from 'src/app/pages/user/user';
import { SwiperModule } from 'swiper/angular';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ButtonComponent } from './common/button/button.component';
import { DashButtonComponent } from './common/dash-button/dash-button.component';
import { IndexListComponent } from './common/index-list/index-list.component';
import { InjectedWrapperComponent } from './common/injected-wrapper/injected-wrapper.component';
import { ListItemComponent } from './common/list-item/list-item.component';
import { NavigationComponent } from './common/navigation/navigation.component';
import { PageHeaderComponent } from './common/page-header/page-header.component';
import { SearchComponent } from './common/search/search.component';
import { WalkthroughComponent } from './common/walkthrough/walkthrough.component';
import { AccessibilityModalComponent } from './components/accessibility-modal/accessibility-modal.component';
import { AppLogoComponent } from './components/app-logo/app-logo.component';
import { CancelBookingModal } from './components/cancel-booking-modal/cancel-booking-modal';
import { ContactModalComponent } from './components/contact-modal/contact-modal.component';
import { CourseProgressBar } from './components/course-progress-bar/course-progress-bar';
import { DiagnosticCompleteModal } from './components/diagnostic-complete-modal/diagnostic-complete-modal';
import { GeneralModalComponent } from './components/general-modal/general-modal.component';
import { GoalReflectionModalComponent } from './components/goal-reflection-modal/goal-reflection-modal';
import { LoginBottomComponent } from './components/login-bottom/login-bottom.component';
import { LoginBrandingLogoComponent } from './components/login-branding-logo/login-branding-logo.component';
import { Map } from './components/map/map';
import { OnboardingReminderModal } from './components/onboarding-reminder/onboarding-reminder-modal';
import { PieChartHour } from './components/pie-chart-hour/pie-chart-hour';
import { PieChart } from './components/pie-chart/pie-chart';
import { ProfileChart } from './components/profile-chart/profile-chart';
import { ProfileHeldModal } from './components/profile-held-modal/profile-held-modal';
import { ProgressBarComponent } from './components/progress-bar/progress-bar';
import { QrScannerComponent } from './components/qr-scanner/qr-scanner';
import { QuestionComponent } from './components/question/question';
import { RatingComponent } from './components/rating/rating';
import { ReflectionCompleteModal } from './components/reflection-complete-modal/reflection-complete-modal';
import { ScoreScaleComponent } from './components/score-scale/score-scale';
import { SkillProgressBarComponent } from './components/skill-progress-bar/skill-progress-bar';
import { SkillStatusComponent } from './components/skill-status/skill-status';
import { SwitchOrgModal } from './components/switch-org-modal/switch-org-modal';
import { ActivitiesPage } from './pages/activities/activities';
import { ActivitySelectPage } from './pages/activity-select/activity-select';
import { CourseQuestionsPage } from './pages/course-questions/course-questions';
import { DiagnosticListPage } from './pages/diagnostic-list/diagnostic-list';
import { DiagnosticQuestionsPage } from './pages/diagnostic-questions/diagnostic-questions';
import { DiagnosticSummaryListPage } from './pages/diagnostic-summary-list/diagnostic-summary-list';
import { DiagnosticSummaryPage } from './pages/diagnostic-summary/diagnostic-summary';
import { GoalsPage } from './pages/goals/goals';
import { LocationMapPage } from './pages/location-map/location-map';
import { OnboardingPage } from './pages/onboarding/onboarding';
import { OrganizationsPage } from './pages/organizations/organizations';
import { QuestionnaireSelectPage } from './pages/questionnaire-select/questionnaire-select';
import { RecommendedComponent } from './pages/recommended/recommended.component';
import { ReflectionDetailsPage } from './pages/reflection-details/reflection-details';
import { ReflectionPage } from './pages/reflection/reflection';
import { RscCookiesPage } from './pages/rsc-cookies/rsc-cookies';
import { RscDiagnosticSummaryComponent } from './pages/rsc-diagnostic-summary/rsc-diagnostic-summary.component';
import { RscReviewAnswersComponent } from './pages/rsc-review-answers/rsc-review-answers.component';
import { Appcalc } from './providers/appcalc';
import { DiagnosticService } from './providers/diagnostic/diagnostic-service';
import { OnboardingService } from './providers/onboarding/onboarding-service';
import { ReflectionService } from './providers/onboarding/reflection-service';
import { QuestionnaireDataProvider } from './providers/questionnaire-data/questionnaire-data';
import { effects, reducers } from './store';
//import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { ModalFocus } from './directives/modalFocus.directive';
import { ErrorMessageComponent } from './error-message/error-message.component';
import { HTTP } from '@awesome-cordova-plugins/http/ngx';
import { FileOpener } from '@awesome-cordova-plugins/file-opener/ngx';

import {MatButtonModule} from '@angular/material/button';
import { SetPasswordComponent } from './set-password/set-password.component';
import { TermsModalComponent } from './components/terms-modal/terms-modal.component';

// const config: SocketIoConfig = {
//     url: "http://localhost:3000/", options: {
//         "transports": [
//             "websocket"
//         ]
//     }
// };
declare var cordova: any;

@NgModule({
    declarations: [
        ModalFocus,
        OnboardingReminderModal,
        ReflectionCompleteModal,
        GoalReflectionModalComponent,
        DiagnosticCompleteModal,
        ProfileHeldModal,
        // directives
        Map,
        PieChart,
        ProfileChart,
        CourseProgressBar,
        PieChart,
        PieChartHour,
        LocationMapPage,
        //Map,
        //pages
        ActivitiesPage,
        AppComponent,
        AboutPage,
        CareerPathSkillTileDetailsPage,
        CareerPathPage,
        UserSettingsPage,
        ActivityListPage,
        ActivityDetailPage,
        ActivityEditPage,
        CourseDetailPage,
        ActivityCompletePage,
        SetPasswordComponent,
        ActivityBookingPage,
        ManagedFilePage,
        UserPage,
        SkillDetailsPage,
        PathwaySuggestedPage,
        PathwayManualPage,
        CoursesListPage,
        GoalEditPage,
        GoalDetailPage,
        GoalsPage,
        GoalListPage,
        SkillsAnalysisSummaryPage,
        EmailSettingsPage,
        PathwayManualCareerTypePage,
        QuestionnaireIntroPage,
        ChooseAPathwayPage,
        QuestionnaireIntroPage,
        PasswordPage,
        FundingListPage,
        LoginPage,
        QuestionnaireSelectPage,
        SkipQuestionnaire,
        QuestionnairePage,
        QuestionComponent,
        ProgressBarComponent,
        OrganizationsPage,
        OnboardingPage,
        DiagnosticListPage,
        DiagnosticSummaryPage,
        RscDiagnosticSummaryComponent,
        RscReviewAnswersComponent,
        DiagnosticQuestionsPage,
        ReflectionDetailsPage,
        CourseQuestionsPage,
        RecommendedComponent,
        ReflectionPage,
        DiagnosticSummaryListPage,
        ActivitySelectPage,
        NavigationComponent,
        ErrorMessageComponent,
        ScoreScaleComponent,
        RatingComponent,
        PageHeaderComponent,
        SkillProgressBarComponent,
        SkillStatusComponent,
        QrScannerComponent,
        CancelBookingModal,
        TermsModalComponent,
        SwitchOrgModal,
        AccessibilityModalComponent,
        GeneralModalComponent,
        ContactModalComponent,
        RscLoginPage,
        RscCookiesPage,
        IndexListComponent,
        ListItemComponent,
        ButtonComponent,
        DashButtonComponent,
        AppLogoComponent,
        LoginBottomComponent,
        LoginBrandingLogoComponent,
        InjectedWrapperComponent,
        SearchComponent,
        WalkthroughComponent
    ],
    imports: [
        CommonModule,
        //SocketIoModule.forRoot(config),
        MatTooltipModule,
        MatProgressSpinnerModule,
        MatSelectModule,
        MatTabsModule,
        MatSliderModule,
        MatRadioModule,
        MatCheckboxModule,
        MatMomentDateModule,
        MatDatepickerModule,
        MatFormFieldModule,
        MatInputModule,
        ReactiveFormsModule,
        BrowserModule,
        FormsModule,
        MatButtonModule,
       // IonicStorageModule.forRoot(),
        MatMenuModule,
        SwiperModule,
        StoreModule.forRoot(([reducers]) as any, {}),
        EffectsModule.forRoot(effects),
        StoreModule.forFeature('app', reducers),
        EffectsModule.forFeature(effects),
        HttpClientModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        NgxCopilotModule,
        StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production })
    ],
    exports: [OnboardingReminderModal, ModalFocus,/*SwiperModule*/, DiagnosticListPage, MatMenuModule, CancelBookingModal, SwitchOrgModal],
    providers: [
        Device,
        StatusBar,
        QuestionnaireDataProvider,
        SplashScreen,
        OnboardingService,
        DiagnosticService,
        ReflectionService,
        Network,
        // SwiperModule,
        InAppBrowser,
        Calendar,
        HTTP,
        FileOpener,
        File,
        Appcalc,
        FileTransfer,
        Camera,
        NavParams,
        BarcodeScanner,
        UntypedFormBuilder,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
        {
            provide: RouteReuseStrategy,
            useClass: IonicRouteStrategy
        }
    ],
    bootstrap: [AppComponent]
})


export class AppModule {

}
